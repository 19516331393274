/**  =====================
      Rating css start
==========================  **/

.br-wrapper {
  margin: 20px 0 50px;
}

.br-theme-bars-1to10 .br-widget .br-current-rating {
  clear: both;
  padding: 0;
  font-size: 13px;
  color: #757575;
  font-weight: 400;
}

.br-theme-bars-reversed .br-widget .br-current-rating {
  clear: both;
  padding: 0;
  line-height: 2;
  font-size: 13px;
}

.br-theme-bars-movie .br-widget .br-current-rating {
  color: #757575;
  font-weight: 400;
  text-align: left;
}

.br-theme-bars-horizontal,
.br-theme-bars-reversed {
  .br-widget .br-current-rating {
    color: #757575;
    font-weight: 400;
    font-size: 13px;
  }
}

span.value {
  display: inline;
}

.current-rating {
  position: relative;
  top: -50px;
  &.hidden {
    display: none;
  }
}

.your-rating {
  position: relative;
  top: -50px;
  &.hidden {
    display: none;
  }
}

.br-theme-bars-1to10,
.br-theme-bars-movie,
.br-theme-bars-reversed,
.br-theme-bars-horizontal {
  .br-widget a {
    background-color: lighten($primary-color, 8%);
    &.br-active,
    &.br-selected {
      background-color: $primary-color;
    }
  }
}

.br-theme-bars-pill {
  .br-widget a {
    background-color: lighten($primary-color, 8%);
    color: $primary-color;
    &.br-active,
    &.br-selected {
      background-color: $primary-color;
    }
  }
}

.br-theme-fontawesome-stars {
  .br-widget {
    a.br-active:after,
    a.br-selected:after {
      color: $primary-color;
    }
  }
}

.br-theme-css-stars {
  .br-widget {
    a.br-active:after,
    a.br-selected:after {
      color: $primary-color;
    }
  }
}

.br-theme-bars-square {
  .br-widget a {
    border: 2px solid lighten($primary-color, 8%);
    color: lighten($primary-color, 8%);
    &.br-active,
    &.br-selected {
      border: 2px solid $primary-color;
      color: $primary-color;
    }
  }
}

.br-theme-fontawesome-stars-o {
  .br-widget {
    a.br-selected:after,
    a:after,
    a.br-active:after,
    a.br-fractional:after {
      color: $primary-color;
    }
  }
}

/**====== Rating css end ======**/
