/**  =====================
      Box-shadow css start
==========================  **/

.box-list div div div {
  margin-bottom: 20px;
}

.box-list {
  padding-bottom: 0;
}

.box-list p {
  margin-bottom: 0 !important;
}

/**====== z-depth-top css start ======**/

$depth-val: -5;
@for $i from 0 through 5 {
  .z-depth-top-#{$i} {
    @include shadow(0 #{$depth-val}px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0));
    @if ($depth-val > -8) {
      $depth-val: $depth-val - 2;
    } @else {
      $depth-val: $depth-val - 1;
    }
  }
}

/**====== z-depth-top css end ======**/

/**====== z-depth-bottom css start ======**/

$depth-val: 5;
@for $i from 0 through 5 {
  .z-depth-bottom-#{$i} {
    @include shadow(0 #{$depth-val}px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0));
    @if ($depth-val < 8) {
      $depth-val: $depth-val + 2;
    } @else {
      $depth-val: $depth-val + 1;
    }
  }
}

/**====== z-depth-bottom css end ======**/

/**====== z-depth-left css start ======**/

$depth-val: -5;
@for $i from 0 through 5 {
  .z-depth-left-#{$i} {
    @include shadow(#{$depth-val}px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0));
    @if ($depth-val > -8) {
      $depth-val: $depth-val - 2;
    } @else {
      $depth-val: $depth-val - 1;
    }
  }
}
/**====== z-depth-left css end ======**/

/**====== z-depth-right css start ======**/

$depth-val: 5;
@for $i from 0 through 5 {
  .z-depth-right-#{$i} {
    @include shadow(#{$depth-val}px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0));
    @if ($depth-val < 8) {
      $depth-val: $depth-val + 2;
    } @else {
      $depth-val: $depth-val + 1;
    }
  }
}

/**====== z-depth-right css end ======**/

/**====== z-depth-all-side css start ======**/

$depth-val: 5;
@for $i from 0 through 5 {
  .z-depth-#{$i} {
    @include shadow(0 0 25px #{$depth-val}px #ccc, 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0));
    @if ($depth-val < 8) {
      $depth-val: $depth-val + 2;
    } @else {
      $depth-val: $depth-val + 1;
    }
  }
}

/**====== z-depth-all-side css end ======**/

/**====== Box-shadow css end ======**/
