/**  =====================
      Generic-class css start
========================== **/

.generic-card-block code {
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

.generic-image-body {
  li {
    padding: 0 20px;
    display: inline-block;
  }
  code {
    display: block;
  }
  img {
    display: block;
    margin: 10px auto 20px;
  }
}

/*====== Padding css starts ======*/

$i: 0;
@while $i<=50 {
  .p-#{$i} {
    padding: #{$i}px;
  }
  $i: $i + 5;
}

/*====== Padding css ends ======*/

/*====== Padding-top css starts ======*/

$i: 0;
@while $i<=50 {
  .p-t-#{$i} {
    padding-top: #{$i}px !important;
  }
  $i: $i + 5;
}

/*====== Padding-top css ends ======*/

/*====== Padding-bottom css starts ======*/

$i: 0;
@while $i<=50 {
  .p-b-#{$i} {
    padding-bottom: #{$i}px !important;
  }
  $i: $i + 5;
}

/*====== Padding-bottom css ends ======*/

/*====== Padding-left css starts ======*/

$i: 0;
@while $i<=50 {
  .p-l-#{$i} {
    padding-left: #{$i}px;
  }
  $i: $i + 5;
}

/*====== Padding-left css ends ======*/

/*====== Padding-right css starts ======*/

$i: 0;
@while $i<=50 {
  .p-r-#{$i} {
    padding-right: #{$i}px;
  }
  $i: $i + 5;
}

/*====== Padding-right css ends ======*/

/*====== Margin css starts ======*/

$i: 0;
@while $i<=50 {
  .m-#{$i} {
    margin: #{$i}px;
  }
  $i: $i + 5;
}

/*====== Margin css ends ======*/

/*====== Margin-top css starts ======*/

$i: 0;
@while $i<=50 {
  .m-t-#{$i} {
    margin-top: #{$i}px;
  }
  $i: $i + 5;
}

/*====== Margin-top css ends ======*/

/*====== Margin-Bottom css starts ======*/

$i: 0;
@while $i<=50 {
  .m-b-#{$i} {
    margin-bottom: #{$i}px;
  }
  $i: $i + 5;
}

/*====== Margin-Bottom css ends ======*/

/*====== Margin-left css starts ======*/

$i: 0;
@while $i<=50 {
  .m-l-#{$i} {
    margin-left: #{$i}px;
  }
  $i: $i + 5;
}

/*====== Margin-left css ends ======*/

/*====== Margin-right css starts ======*/

$i: 0;
@while $i<=50 {
  .m-r-#{$i} {
    margin-right: #{$i}px;
  }
  $i: $i + 5;
}

/*====== Margin-right css ends ======*/

/*====== Display css starts ======*/

$i: none, inline-block, inline-flex;
@each $val in $i {
  .d-#{$val} {
    display: $val;
  }
}

/*====== display css ends ======*/

/*====== Border-radius css starts ======*/

$i: 0;
@while $i<=10 {
  .b-radius-#{$i} {
    border-radius: #{$i}px !important;
  }
  $i: $i + 5;
}

/*====== Border-radius css ends ======*/

/*====== Font-size css starts ======*/

$i: 10;
@while $i<=80 {
  .f-#{$i} {
    font-size: #{$i}px;
  }
  $i: $i + 2;
}

/*====== Font-size css ends ======*/

/*====== Font-weight css starts ======*/

$i: 100, 300, 400, 600, 700, 900;
@each $val in $i {
  .f-w-#{$val} {
    @if ($val==900) {
      font-weight: 600;
    } @else {
      font-weight: $val;
    }
  }
}

/*====== Font-weight css ends ======*/

/*====== Font-style css starts ======*/

$i: normal, italic, oblique, initial, inherit;
@each $val in $i {
  .f-s-#{$val} {
    font-style: $val;
  }
}

/*====== Font-style css ends ======*/

/*====== Text-align css starts ======*/

$i: center, left, right;
@each $val in $i {
  .text-#{$val} {
    text-align: $val;
  }
}

/*====== Text-align css ends ======*/

/*====== Text-Transform css starts ======*/

$i: capitalize, uppercase, lowercase;
@each $val in $i {
  .text-#{$val} {
    text-transform: $val;
  }
}

/*====== Text-Transform css ends ======*/

/*====== Text-Decoration css starts ======*/

$i: overline, line-through, underline;
@each $val in $i {
  .text-#{$val} {
    text-decoration: $val;
  }
}

/*====== Text-Decoration css ends ======*/

/*====== Vertical-Align css starts ======*/

$i: baseline, sub, super, top, text-top, middle, bottom, text-bottom, initial, inherit;
@each $val in $i {
  .#{$val} {
    vertical-align: $val;
  }
}

/*====== Vertical-Align css ends ======*/

/*====== Position css starts ======*/

$i: static, absolute, fixed, relative, initial, inherit;
@each $val in $i {
  .pos-#{$val} {
    position: $val;
  }
}

/*====== Position css ends ======*/

/*====== Float css starts ======*/

$i: left, right, none;
@each $val in $i {
  .f-#{$val} {
    float: $val;
  }
}

/*====== Float css ends ======*/

/*====== Overflow css starts ======*/

$i: hidden, visible, auto;
@each $val in $i {
  .o-#{$val} {
    overflow: $val;
  }
}

/*====== Overflow css ends ======*/

/*====== Image-sizes css starts ======*/

$i: 20;
@while $i<=100 {
  .img-#{$i} {
    width: #{$i}px;
  }
  $i: $i + 10;
}

/*====== Image-sizes css ends ======*/

/*======= Text css starts ===========*/

$custom-color: #64b0f2;
$pink-color: #ff7aa3;
$muted-color: #919aa3;
$dark-color: #2b3d51;
$purple-color: #9261c6;
$muted-color: #919aa3;
$text-name: text-primary, text-warning, text-default, text-danger, text-success, text-inverse, text-info, text-custom, text-pink, text-muted,
  text-dark, text-purple, text-muted;
$text-color: $primary-color, $warning-color, $default-color, $danger-color, $success-color, $inverse-color, $info-color, $custom-color, $pink-color,
  $muted-color, $dark-color, $purple-color, $muted-color;
@each $var in $text-name {
  $i: index($text-name, $var);
  .#{$var} {
    color: nth($text-color, $i) !important;
  }
}

/*======= Text css ends ===========*/

/*======= Label-color css starts  ======= */

.label {
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 0;
  text-transform: capitalize;
}

$label-name: primary, warning, default, danger, success, inverse, info;
$label-color: $primary-color, $warning-color, $default-color, $danger-color, $success-color, $inverse-color, $info-color;
@each $var in $label-name {
  $i: index($label-name, $var);
  .label-#{$var} {
    background: linear-gradient(45deg, nth($label-color, $i), lighten(nth($label-color, $i), 10%));
  }
}
.label-warning {
  color: #fff;
}

/*======= Label-color css starts  ======= */

/*======= Badge-color css starts  ======= */

$badge-name: primary, warning, default, danger, success, inverse, info;
$badge-color: $primary-color, $warning-color, $default-color, $danger-color, $success-color, $inverse-color, $info-color;
@each $var in $badge-name {
  $i: index($badge-name, $var);
  .badge-#{$var} {
    background: linear-gradient(45deg, nth($badge-color, $i), lighten(nth($badge-color, $i), 10%));
  }
}

/*======= Badge-color css end  ======= */

/*======= Background-color css starts  ======= */

.bg-color-box {
  text-align: center;
  display: inline-block;
  padding: 10px 20px;
  box-shadow: 0 0 5px 0 rgba(128, 128, 128, 0.49);
  font-weight: 600;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.bg-color-box span {
  color: #fff;
}

$background-name: primary, warning, default, danger, success, inverse, info;
$background-color: $primary-color, $warning-color, $default-color, $danger-color, $success-color, $inverse-color, $info-color;
@each $var in $background-name {
  $i: index($background-name, $var);
  .bg-#{$var} {
    background-color: nth($label-color, $i) !important;
    color: #fff;
  }
}

/*======= Background-color css end  ======= */

/*======= Button-color css starts  ======= */

$btn-name: primary, warning, default, danger, success, inverse, info;
$btn-color: $primary-color, $warning-color, $default-color, $danger-color, $success-color, $inverse-color, $info-color;
@each $var in $btn-name {
  $i: index($btn-name, $var);
  .color-#{$var} {
    background-color: nth($btn-color, $i);
  }
}

/*======= Button-color css ends  ======= */

/*======= Loader-color css starts  ======= */

$loader-name: primary, warning, default, danger, success, inverse, info;
$loader-color: $primary-color, $warning-color, $default-color, $danger-color, $success-color, $inverse-color, $info-color;
@each $var in $loader-name {
  $i: index($loader-name, $var);
  .loader-#{$var} {
    background-color: nth($loader-color, $i) !important;
  }
}

/*======= Loader-color css ends  ======= */

/*======= Nestable-color css starts  ======= */

$nestable-name: primary, warning, default, danger, success, inverse, info;
$nestable-color: $primary-color, $warning-color, $default-color, $danger-color, $success-color, $inverse-color, $info-color;
@each $var in $nestable-name {
  $i: index($nestable-name, $var);
  .nestable-#{$var} {
    background-color: nth($nestable-color, $i) !important;
    border-color: nth($nestable-color, $i);
    color: #fff !important;
  }
}

/*======= Nestable-color css ends  ======= */

/*======= Table-Border-Bottom-color css starts  ======= */

$border-name: primary, warning, default, danger, success, inverse, info;
$border-color: $primary-color, $warning-color, $default-color, $danger-color, $success-color, $inverse-color, $info-color;
@each $var in $border-name {
  $i: index($border-name, $var);
  table thead .border-bottom-#{$var} th,
  table tbody .border-bottom-#{$var} th,
  table tbody .border-bottom-#{$var} td {
    border-bottom: 1px solid nth($border-color, $i);
  }
}

/*======= Table-Border-Bottom-color css ends  ======= */

/*======= Table styling css starts  ======= */

$table-name: primary, warning, default, danger, success, inverse, info;
$table-color: $primary-color, $warning-color, $default-color, $danger-color, $success-color, $inverse-color, $info-color;
@each $var in $table-name {
  $i: index($table-name, $var);
  .table-styling .table-#{$var},
  .table-styling.table-#{$var} {
    background-color: nth($border-color, $i);
    color: #fff;
    border: 3px solid nth($border-color, $i);
    thead {
      background-color: darken(nth($border-color, $i), 10%);
      border: 3px solid darken(nth($border-color, $i), 10%);
    }
  }
}

/*======= Table styling css ends  ======= */

/*======= Toolbar css starts  ======= */

$light: #bdc3c7;
$dark: #34495e;
$toolbar-name: primary, warning, light, danger, success, dark, info;
$toolbar-color: $primary-color, $warning-color, $default-color, $danger-color, $success-color, $inverse-color, $info-color;
@each $var in $toolbar-name {
  $i: index($toolbar-name, $var);
  .toolbar-#{$var} .tool-item {
    background: nth($toolbar-color, $i) !important;
  }
  .toolbar-#{$var} .tool-item.selected,
  .toolbar-#{$var} .tool-item:hover {
    background: darken(nth($toolbar-color, $i), 10%) !important;
  }
  .toolbar-#{$var}.tool-top .arrow {
    border-color: nth($toolbar-color, $i) transparent transparent;
  }
  .toolbar-#{$var}.tool-bottom .arrow {
    border-color: transparent transparent nth($toolbar-color, $i);
  }
  .toolbar-#{$var}.tool-left .arrow {
    border-color: transparent transparent transparent nth($toolbar-color, $i);
  }
  .toolbar-#{$var}.tool-right .arrow {
    border-color: transparent nth($toolbar-color, $i) transparent transparent;
  }
  .btn-toolbar-#{$var}.pressed {
    background-color: nth($toolbar-color, $i);
  }
}

/*======= Toolbar css ends  ======= */

/*======= Card-Border-Top-color css starts  ======= */

$card-name: primary, warning, default, danger, success, inverse, info;
$card-color: $primary-color, $warning-color, $default-color, $danger-color, $success-color, $inverse-color, $info-color;
@each $var in $card-name {
  $i: index($card-name, $var);
  .card-border-#{$var} {
    border-top: 4px solid nth($border-color, $i);
  }
}

/*======= Card-Border-Top-color css ends  ======= */

/*======= Panels-wells-color css starts  ======= */

$panel-name: primary, warning, default, danger, success, inverse, info;
$panel-color: $primary-color, $warning-color, $default-color, $danger-color, $success-color, $inverse-color, $info-color;
@each $var in $panel-name {
  $i: index($panel-name, $var);
  .panels-wells .panel-#{$var} {
    border-color: nth($border-color, $i);
  }
}

/*======= Panels-wells-color css ends  ======= */

/*======= All-Borders-color css starts  ======= */

$b-name: primary, warning, default, danger, success, inverse, info, theme;
$b-color: $primary-color, $warning-color, $default-color, $danger-color, $success-color, $inverse-color, $info-color, $theme-border;
@each $var in $b-name {
  $i: index($b-name, $var);
  .b-t-#{$var} {
    border-top: 1px solid nth($b-color, $i);
  }
  .b-b-#{$var} {
    border-bottom: 1px solid nth($b-color, $i);
  }
  .b-l-#{$var} {
    border-left: 1px solid nth($b-color, $i);
  }
  .b-r-#{$var} {
    border-right: 1px solid nth($b-color, $i);
  }
}

/*======= All-Borders-color css ends  ======= */
/*======= All-color css start  ======= */
@each $value in $social-name {
  $i: index($social-name, $value);

  .bg-#{$value} {
    background: linear-gradient(45deg, nth($social-color, $i), lighten(nth($social-color, $i), 10%));
  }

  .text-#{$value} {
    color: nth($social-color, $i);
  }
}
@each $value in $color-name {
  $i: index($color-name, $value);

  .bg-#{$value} {
    background: linear-gradient(45deg, nth($color-color, $i), lighten(nth($color-color, $i), 10%));
  }

  .text-#{$value} {
    color: nth($color-color, $i);
  }
}
/*======= All-color css ends  ======= */

/**====== Generic-class css end ======**/
