.widget-statstic-card {
  position: relative;
  overflow: hidden;
  .card-header {
    border-bottom: 0px solid transparent;
  }
  .st-icon {
    color: #fff;
    font-size: 23px;
    padding: 40px 40px 20px 20px;
    border-radius: 50%;
    position: absolute;
    top: -30px;
    right: -30px;
    transition: all 0.3s ease-in-out;
  }

  h2 {
    font-weight: 600;
    display: inline-block;
  }

  span {
    border-radius: 30px;
    padding: 5px 20px;
    font-weight: 600;
  }
  &:hover {
    .st-icon {
      font-size: 50px;
    }
  }
}
.card-comment {
  transition: all ease-in-out 0.5s;

  .card-block-small {
    border-bottom: 1px solid #f1f1f1;

    .comment-desc {
      h6 {
        font-weight: 600;
      }

      .btn-comment {
        font-size: 13px;
        padding: 5px 20px;
        line-height: 1;
        color: #fff;
      }
    }

    &:hover {
      background-color: #f1f1f1;
    }
  }
  img {
    float: left;
    width: 60px;
  }

  .comment-desc {
    padding-left: 80px;
    vertical-align: top;
  }

  .comment-btn,
  .date {
    display: inline-block;
  }

  .date {
    float: right;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .card-comment .date {
      float: none;
      margin-top: 20px;
    }
  }
  @media only screen and (max-width: 575px) {
    .card-comment .comment-desc {
      padding-left: 60px;
    }

    .card-comment .date {
      float: none;
      display: block;
      margin-top: 20px;
    }
  }
}
.review-project {
  thead {
    th {
      border-bottom: none;
      border-top: none;
    }
  }

  tbody {
    tr {
      &:first-child {
        td {
          border-top: none;
        }
      }

      td {
        h6,
        p {
          font-weight: 600;
          margin-bottom: 0;
          line-height: 1.4;
        }
      }
    }
  }

  .peity {
    width: 30px;
    height: 30px;
  }

  img {
    vertical-align: top;
  }

  .project-contain {
    margin-left: 15px;
    display: inline-block;
  }

  .progress {
    width: 100%;
    height: 8px;
    background-color: #cdd8dc;
    border-radius: 30px;
    position: relative;
    overflow: inherit;

    .progress-bar {
      border-radius: 30px;
    }

    label {
      position: absolute;
      top: -22px;
      right: 0;
      color: #222;
      font-weight: 600;
      font-size: 12px;
    }
  }
}
.project-task {
  thead {
    th {
      border-bottom: none;
      border-top: none;
    }
  }

  tbody {
    tr {
      cursor: pointer;
      td {
        vertical-align: middle;
      }
    }
  }

  .task-contain {
    h6 {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      color: #fff;
      line-height: 2.5;
      margin-bottom: 0;
      font-weight: 600;
      padding: 3px;
    }
  }

  .progress {
    width: 55%;
    height: 5px;
    text-align: center;
    margin: 0 auto;
    background-color: #e5e5e5;
    border-radius: 30px;

    .progress-bar {
      border-radius: 30px;
    }
  }
}
.review-task {
  .table {
    td {
      vertical-align: middle;

      img {
        display: inline-block;
      }

      p {
        display: inline-block;
        margin-bottom: 0;
      }

      h6 {
        margin-bottom: 0;
        font-size: 0.9rem;
        font-weight: 600;
      }

      .review-date {
        margin-bottom: 0;
        position: relative;
        padding-right: 15px;

        &:after {
          content: '|';
          position: absolute;
          font-size: 15px;
          padding: 0 6px;
        }
      }

      i {
        & + i {
          margin-left: 5px;
        }
      }
    }

    tbody {
      tr {
        cursor: pointer;
        &:first-child {
          td {
            border-top: none;
          }
        }
      }
    }
  }
}
.card-power {
  h2 {
    font-weight: 500;
    display: inline-block;
  }

  span {
    font-size: 20px;
    margin-left: 5px;
  }

  .map-area {
    h6 {
      display: inline-block;
      font-weight: 600;
    }

    span {
      font-size: inherit;
      margin-left: 0;
      font-weight: 600;
    }

    p {
      line-height: 18px;
    }
  }
}
// Amount card start

.amount-card {
  color: #fff;

  h4 {
    text-align: center;
  }

  i {
    position: absolute;
    font-size: 25px;
    top: 25px;
    left: 20px;
  }

  p {
    text-align: center;
    position: absolute;
    margin-bottom: 0;
    bottom: 10px;
    left: 0;
    right: 0;
    color: $light-color;
  }

  #amunt-card1,
  #amunt-card2,
  #amunt-card3,
  #amunt-card4 {
    height: 80px !important;
    width: 100%;
  }
}

#Statistics-chart {
  height: 200px;
  width: 100%;
  @media only screen and (min-width: 1400px) {
    height: 260px;
  }
}

#process-complience-chart {
  height: 200px;
  width: 100%;
}
// Amount card end
// notification card start

.notification-card {
  text-align: center;
  color: #fff;

  .card-block {
    > div {
      margin-left: 0;
      margin-right: 0;
    }
    padding: 0;

    .notify-icon i {
      font-size: 30px;
    }

    .notify-cont {
      padding: 15px 0;
      border-left: 1px solid #fff;

      p {
        margin-bottom: 0;
      }
    }
  }
}
// notification card end
// order card start

.order-card {
  color: #fff;

  i {
    font-size: 26px;
  }
}
// order card end
// user visitor card strat

.map-visitor-card {
  .card-header {
    h5 {
      color: #fff;
    }
  }

  .card-footer {
    background-color: #fff;

    .progress {
      height: 4px;
    }
  }
}
// feed blog end
