$menu-title: #000, #624e89, #3c4fb1, #919aa3, #999, #fff;

$header-theme: #3b4650, #473b7b, #000000, #ff5858, #4099ff, #2d0102;
$header-right-theme: #647688, #30d2be, #0fb3c2, #f09819, #6e7ff3, #091991;

//#038f9e, #4DD0E1

$header-theme-light: #11c788, #ff5252, #ff784f, #38a9f9, #4099ff, #39adb5;
$header-right-theme-light: #54e2b7, #f48fb1, #ff5370, #43e9b3, #73b4ff, #7c4dff;

$box-bg-color: #3b4650, #f78ea4, #6e7ff3, #39adb5, #4099ff, #f09819;

$left-navbar-theme: #3b4650;
$left-navbar-light-theme: #fff;

$active-item: #3b4650, #473b7b, #000000, #ff5858, #4099ff, #2d0102, #11c788, #ff5252, #ff784f, #38a9f9, #038f9e, #39adb5;
$icon-color: #4099ff, #f78ea4, #2ed8b6, #f1c40f, #ab7967, #39adb5, #7c4dff, #ff5370;
.pcoded {
  &[nav-type='st1'] {
    .pcoded-item.pcoded-left-item {
      $i: 1;
      @for $k from 1 through 3 {
        @each $value in $icon-color {
          > li {
            &:nth-child(#{$i}) {
              > a > .pcoded-micon {
                color: $value;
              }
            }
          }
          $i: $i + 1;
        }
      }
    }
  }

  &[nav-type='st2'] {
    .pcoded-item.pcoded-left-item {
      > li {
        > a > .pcoded-micon {
          color: $inverse-color;
        }
      }
    }
  }

  &[nav-type='st1'],
  &[nav-type='st2'] {
    .pcoded-item.pcoded-left-item {
      > li {
        > a > .pcoded-micon {
          color: $inverse-color;

          b {
            display: none;
          }
        }
      }
    }
  }
}
// icon color end
/**  =====================
      Left-side-menu css start
==========================  **/

.main-menu {
  float: left;
  width: 235px;
  z-index: 99;
  @media only screen and (max-width: 992px) {
    height: 100% !important;
  }

  .main-menu-header {
    align-items: center;
    display: flex;
    padding: 30px 20px 15px;

    .user-details {
      display: inline-block;
      margin-left: 15px;
      margin-right: 15px;

      #more-details {
        cursor: pointer;
      }

      span {
        color: #000;
        display: block;
        font-size: 14px;
        text-transform: capitalize;

        i {
          font-size: 10px;
          margin-left: 10px;
        }

        &:first-child {
          margin-bottom: 5px;
          font-weight: 600;
        }

        &:last-child {
          color: #999;
          font-size: 12px;
        }
      }
    }
  }

  .main-menu-content {
    .more-details {
      display: none;

      a {
        padding: 10px 25px;
        display: block;
        color: #000;
        transition: all ease-in 0.3s;

        &:hover {
          color: $primary-color;
        }

        i {
          margin-right: 10px;
        }
      }
    }

    .menu-search {
      padding: 20px 20px 10px;
    }

    li {
      position: relative;
    }
  }
}

.pcoded[theme-layout='vertical'][vertical-nav-type='collapsed'] {
  .main-menu-content,
  .main-menu-header {
    display: none;
  }

  .main-menu {
    width: 56px;
  }
}

.sidebar_toggle {
  a {
    margin: -5px;
    line-height: 1;
  }
}
/**  =====================
      Main-header css start
==========================  **/
.header-navbar {
  min-height: 56px;
  padding: 0;

  .navbar-wrapper {
    .navbar-logo {
      position: relative;
      align-items: center;
      display: flex;
      float: left;
      height: 56px;
      text-align: center;
      text-transform: uppercase;
      width: 235px;
      padding: 10px;
      @media only screen and (max-width: 992px) {
        justify-content: center;
      }

      h5 {
        margin-bottom: 0;
      }

      .mobile-menu {
        position: absolute;
        right: 22px;
        top: calc(50% - 10px);
        font-size: 16px;
      }
      @media only screen and (max-width: 768px) {
        .mobile-search {
          display: none !important;
        }
      }

      .mobile-options,
      .mobile-search {
        display: none;
      }
    }

    .navbar-container {
      .nav-left {
        float: left;
        margin-bottom: 0;

        li {
          float: left;
          line-height: 3.5;
          padding: 0 10px;
          position: relative;
          @media only screen and (max-width: 575px) {
            padding: 0 5px;
          }

          .sidebar_toggle a {
            display: none;
          }

          > a {
            font-size: 16px;

            > i {
              &.fi {
                border-radius: 50%;
                width: 20px;
                height: 20px;
              }
            }
          }
        }

        a {
          padding: 0 0.6rem;
        }

        .mega-menu-top {
          i {
            font-size: 10px;
            margin-left: 10px;
          }
        }
      }

      .nav-right {
        float: right;
        @extend .nav-left;

        .user-profile {
          img {
            margin-right: 10px;
            width: 40px;
          }

          i {
            font-size: 10px;
            margin-left: 10px;
          }
        }
      }

      .header-notification {
        perspective: 1000px;
        z-index: 99;
        position: relative;

        .show-notification {
          background: #fff;
          box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          opacity: 1;
          position: absolute;
          right: 0;
          visibility: visible;
          width: 24rem;
          display: none;
          transform: translateY(20px);

          a {
            color: $theme-font-color;
          }

          li {
            border-top: 1px solid $border-color;
            line-height: initial;
            padding: 1em 20px;
            width: 100%;
            transition: all 0.3s ease-in-out;

            &:first-child {
              padding: 10px 20px;

              h6 {
                display: inline-block;
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 0;
              }

              label {
                float: right;
              }
            }

            img {
              width: 40px;
              margin-right: 10px;
              align-self: flex-start !important;
            }

            &:hover {
              background-color: #f1f1f1;
            }
          }

          &.row li {
            &:hover {
              background-color: transparent;
            }
          }

          .notification-user {
            font-size: 15px;
            font-weight: 600;
            margin-bottom: 5px;
          }

          .notification-msg {
            margin-bottom: 5px;
          }

          .notification-time {
            font-size: 12px;
            color: #919aa3;
          }
        }

        .profile-notification {
          @extend .show-notification;
          width: 17em;

          a {
            font-size: 15px;
            padding-left: 0;
          }

          i {
            font-size: 15px;
            margin-left: 0;
            margin-right: 5px;
          }

          li {
            border-top: none;
            padding: 0.7em 20px;

            &:first-child {
              padding: 0.7em 20px;
            }
          }
        }

        &.active {
          &:before {
            content: '';
            border: 10px solid transparent;
            border-left-color: #fff;
            border-top-color: #fff;
            position: absolute;
            border-radius: 5px;
            bottom: -32px;
            right: 20px;
            transform: rotate(45deg);
            z-index: 9;
          }
        }
      }

      .badge {
        border-radius: 100px;
        right: 15px;
        position: absolute;
        top: 14px;
        padding: 4px;

        &:after,
        &:before {
          content: '';
          width: 100%;
          height: 100%;
          border-radius: 50%;
          position: absolute;
          top: 0;
          left: 0;
          box-shadow: inset 0 0 20px 5px rgba(255, 255, 255, 0.4);
        }

        &:after {
          animation: not-blink 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
        }

        &:before {
          animation: not-blink 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
          animation-delay: 1.15s;
        }
      }
    }

    .header-search {
      line-height: 0.8;

      .main-search {
        padding: 13px 0;

        .input-group {
          margin-bottom: 0;
          background-color: transparent;
          border-radius: 20px;

          .form-control {
            padding: 0.3rem 0.75rem;
            // display: none;
            width: 0;
            border: none;
            background-color: transparent;
          }

          .input-group-addon {
            padding: 0.3rem 0.75rem 0.3rem 0;
            background-color: transparent;
            border: none;

            &.search-close {
              padding: 0.3rem 0 0.3rem 0.75rem;
              display: none;
            }
          }
        }

        &.open {
          color: #000;

          .input-group {
            background-color: #fff;

            .form-control {
              display: block;
            }

            .input-group-addon {
              color: #000;

              &.search-close {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
@keyframes not-blink {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(4);
    opacity: 0;
  }
}
/*======= Main-header css end ======*/
.pcoded {
  .pcoded-container {
    position: relative;
    background: #f3f3f3;
  }

  .pcoded-header {
    position: relative;
    display: block;
  }

  .pcoded-navbar {
    display: block;
    box-shadow: 2px 0 2.94px 0.06px rgba(4, 26, 55, 0.16);
  }
}

.pcoded-main-container {
  display: block;
  position: relative;
  background: #f6f7fb;
  min-height: calc(100vh - 56px);
}

.pcoded .pcoded-content {
  position: relative;
  display: block;
}

.pcoded-inner-navbar {
  display: block;
  position: relative;
}

.pcoded-inner-content {
  padding: 10px;
}

.pcoded {
  .pcoded-navbar .pcoded-item {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;

    > li {
      display: block;
      list-style: outside none none;
      margin: 0;
      padding: 0;
      position: relative;

      > a {
        display: block;
        font-size: 14px;
        padding: 0 15px;
        text-decoration: none;
        position: relative;
        transition: all 0.3s ease-in-out;

        &.disabled {
          opacity: 0.5;
          cursor: not-allowed !important;
        }
      }

      &.active {
        > a {
          font-weight: 600;
        }
      }
    }

    .pcoded-hasmenu .pcoded-submenu {
      list-style: outside none none;
      margin: 0;
      padding: 0;

      li {
        display: block;
        list-style: outside none none;
        margin: 0;
        padding: 0;
        position: relative;

        > a {
          display: block;
          font-size: 14px;
          font-weight: 300;
          padding: 0 15px;
          text-decoration: none;
          position: relative;

          > .pcoded-mtext {
            transition: all 0.3s ease-in-out;

            &:before {
              transition: all 0.3s ease-in-out;
            }
          }
        }

        > a .pcoded-micon {
          font-size: 10px;
          padding-right: 5px;
        }
      }
    }

    > li > a > .pcoded-micon {
      font-size: 14px;
      padding: 4px;
      margin-right: 10px;
      color: #fff;
      border-radius: 4px;
      width: 30px;
      display: inline-block;
      height: 30px;
      text-align: center;

      b {
        margin: 0 2px;
        font-size: 12px;
        line-height: 1;
        font-weight: 600;
      }
    }
  }
  // &[vertical-placement="left"] {
  //     .pcoded-navbar .pcoded-item {
  //         .pcoded-hasmenu .pcoded-submenu {
  //             li {
  //                 &:hover {
  //                     > a {
  //                         .pcoded-mtext {
  //                             margin-left: 5px;
  //
  //                             &:before {
  //                                 padding-right: 15px !important;
  //                             }
  //                         }
  //                     }
  //                 }
  //             }
  //         }
  //     }
  // }
  //
  // &[vertical-placement="right"] {
  //     .pcoded-navbar .pcoded-item {
  //         .pcoded-hasmenu .pcoded-submenu {
  //             li {
  //                 &:hover {
  //                     > a {
  //                         .pcoded-mtext {
  //                             margin-right: 5px;
  //
  //                             &:before {
  //                                 padding-left: 15px !important;
  //                             }
  //                         }
  //                     }
  //                 }
  //             }
  //         }
  //     }
  // }
  &[vertical-placement='right'] {
    .main-body .page-wrapper .page-header-title i {
      margin-right: 0;
      margin-left: 20px;
    }
  }
}

.pcoded-inner-navbar {
  height: 100%;
}

.pcoded[theme-layout='vertical'] {
  .pcoded-header {
    width: 100%;
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.13);
    z-index: 1029;

    .pcoded-left-header {
      display: block;
      z-index: 1028;

      .pcoded-logo {
        opacity: 1;
        text-align: center;
        visibility: visible;
        height: 50px;
        white-space: nowrap;

        a {
          display: block;
          text-decoration: none;
          padding: 9px 0;
        }

        .logo-icon {
          bottom: -21px;
          font-size: 60px;
          left: 8px;
          position: absolute;
          z-index: -1;
          transition: all 0.3s linear;
          -webkit-transition: all 0.3s linear;
          -ms-transition: all 0.3s linear;
          -moz-transition: all 0.3s linear;
          -o-transition: all 0.3s linear;
        }
      }
    }
  }

  &[vertical-nav-type='collapsed'] .pcoded-header.iscollapsed .pcoded-left-header .pcoded-logo .logo-icon {
    font-size: 45px;
    bottom: -7px;
    left: 5px;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
  }

  &[pcoded-device-type='desktop'] .pcoded-header .pcoded-left-header .pcoded-logo .logo-text,
  &[pcoded-device-type='phone'] .pcoded-header.iscollapsed .pcoded-left-header .pcoded-logo .logo-text,
  &[pcoded-device-type='tablet'] .pcoded-header .pcoded-left-header .pcoded-logo .logo-text {
    font-family: open sans;
    font-size: 16px;
    font-weight: 600;
    left: 56px;
    margin: 0 auto !important;
    opacity: 1;
    position: absolute;
    text-transform: uppercase;
    top: 25px;
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    visibility: visible;
  }

  &[pcoded-device-type='phone'] .pcoded-header.nocollapsed .pcoded-left-header .pcoded-logo .logo-text {
    font-family: open sans;
    font-size: 16px;
    font-weight: 400;
    margin: 0 auto !important;
    opacity: 1;
    visibility: visible;
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    position: relative;
    top: 5px;
  }

  &[vertical-nav-type='collapsed'] .pcoded-header.iscollapsed .pcoded-left-header .pcoded-logo .logo-text {
    opacity: 0;
    position: absolute;
    transform: rotateX(90deg);
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    -o-transform: rotateX(90deg);
    visibility: hidden;
  }

  &[vertical-nav-type='offcanvas'] .pcoded-header.iscollapsed .pcoded-left-header .pcoded-logo {
    opacity: 0;
    visibility: hidden;
    transform: rotateX(90deg);
    -webkit-transform: rotateX(90deg);
    -ms-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    -o-transform: rotateX(90deg);
  }
  &[vertical-nav-type='offcanvas'] .pcoded-navbar {
    opacity: 0;
  }

  &[vertical-nav-type='compact'] .pcoded-header .pcoded-left-header .pcoded-logo a .hide-in-smallsize {
    display: none;
  }

  .pcoded-header .sidebar_toggle a {
    border-radius: 4px;
    float: left;
    font-size: 18px;
    height: 35px;
    margin-right: 5px;
    position: relative;
    text-align: center;
    top: 7px;
    width: 40px;
    border-width: 0;
    border-style: solid;

    i {
      position: relative;
      top: -7px;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

.sidebar_toggle a:focus {
  text-decoration: none;
}

.pcoded[theme-layout='vertical'] {
  .pcoded-header .pcoded-right-header {
    line-height: 50px;
    padding: 0 15px;

    .pcoded-rl-header {
      float: left;
    }

    .pcoded-rr-header {
      float: right;
    }

    .pcoded-rl-header {
      padding-left: 20px;
    }
  }

  &[pcoded-device-type='phone'] .pcoded-header .pcoded-right-header .pcoded-rl-header {
    padding-left: 0;
  }

  .pcoded-header .pcoded-right-header .pcoded-rl-header ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
  }
}

.pcoded-right-header .pcoded-rr-header ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.pcoded[theme-layout='vertical'] {
  .pcoded-header .pcoded-right-header {
    .pcoded-rl-header ul > li,
    .pcoded-rr-header ul > li {
      float: left;
      list-style: outside none none;
      margin: 0;
      padding: 0 5px;
      position: relative;
    }

    .pcoded-rl-header ul > li > a,
    .pcoded-rr-header ul > li > a {
      font-size: 14px;
      font-weight: 500;
      text-decoration: none;
    }

    .pcoded-rl-header li.icons > a > i,
    .pcoded-rr-header li.icons > a > i {
      font-size: 18px;
      position: relative;
      top: 3px;
    }

    .pcoded-rl-header li.icons > a,
    .pcoded-rr-header li.icons > a {
      padding: 0 5px;
    }
  }

  .pcoded-right-header .pcoded-badge {
    background-color: #777;
    border-radius: 10px;
    color: #fff;
    font-size: 10px;
    font-weight: 700;
    line-height: 1;
    min-height: 17px;
    min-width: 10px;
    opacity: 1;
    padding: 4px 7px;
    position: absolute;
    right: -4px;
    text-align: center;
    top: 9px;
    transition: opacity 0.3s linear 0s;
    -webkit-transition: opacity 0.3s linear 0s;
    -ms-transition: opacity 0.3s linear 0s;
    -moz-transition: opacity 0.3s linear 0s;
    -o-transition: opacity 0.3s linear 0s;
    vertical-align: middle;
    visibility: visible;
    white-space: nowrap;
  }

  &[pcoded-device-type='phone'] .hide-small-device {
    display: none;
  }

  .pcoded-navbar {
    z-index: 1027;
    height: 100%;
  }

  .pcoded-content {
    position: relative;
    display: block;
  }

  .pcoded-container {
    overflow: hidden;
    position: relative;
    margin: 0 auto;
  }

  &.pcoded-wrapper,
  .pcoded-main-container {
    position: relative;
    margin: 0 auto;
  }

  .pcoded-navbar .pcoded-search {
    padding: 20px 20px 5px;
    position: relative;
  }

  &[vnavigation-view='view2'] .pcoded-navbar .pcoded-search {
    border-radius: 4px 4px 0 0;
  }
}

.pcoded-search input[type='text'] {
  display: block;
  margin: 0;
  width: 100%;
  font-size: 14px;
  -webkit-appearance: none;
  appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background: transparent;
  padding: 6px 22px 6px 10px;
  border: none;
  border: 1px solid #ddd;

  &:focus {
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
}

.style-4 input[type='text'].focus {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.pcoded-search .search-icon {
  cursor: pointer;
  margin-top: -33px;
  padding: 6px;
  position: absolute;
  right: 25px;
}

.pcoded[vertical-nav-type='collapsed'] {
  .pcoded-search-box {
    visibility: hidden;
    opacity: 0;
    transform: rotateX(90deg);
    -webkit-transform: rotateX(90deg);
    -ms-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    -o-transform: rotateX(90deg);
    position: absolute;
  }

  .pcoded-search input[type='text'] {
    padding: 6px 22px 6px 10px;
  }
}

.searchbar-toggle:before {
  content: '\f002';
  cursor: pointer;
  float: right;
  font-family: FontAwesome;
  left: 0;
  position: absolute;
  top: 10px;
  visibility: hidden;
  opacity: 0;
  transform: rotateX(90deg);
  -webkit-transform: rotateX(90deg);
  -ms-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  -o-transform: rotateX(90deg);
}

.pcoded[vertical-nav-type='collapsed'] {
  .searchbar-toggle:before {
    left: 35px;
    top: 10px;
    visibility: visible;
    opacity: 1;
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
  }

  .pcoded-search {
    &.open {
      .searchbar-toggle:before {
        content: '\f05c';
      }

      .pcoded-search-box {
        margin-left: 60px;
        opacity: 1;
        position: absolute;
        top: 0;
        transform: rotateX(0deg);
        -webkit-transform: rotateX(0deg);
        -ms-transform: rotateX(0deg);
        -moz-transform: rotateX(0deg);
        -o-transform: rotateX(0deg);
        visibility: visible;
        width: 200px;
        padding-right: 10px;
      }
    }

    .search-icon {
      margin-top: -35px;
    }
  }

  &[vertical-placement='right'] {
    .pcoded-search {
      &.open {
        .pcoded-search-box {
          margin-left: 0;
          margin-right: 70px;
        }
      }
    }
  }
}

.pcoded {
  &[theme-layout='vertical'] {
    .pcoded-navbar .pcoded-item {
      > li > a {
        text-align: left;
        padding: 5px 20px;
      }

      .pcoded-hasmenu .pcoded-submenu {
        position: relative;
        width: 100%;

        li > a {
          text-align: left;
          padding: 8.7px 10px;
        }
      }
    }

    &[vertical-nav-type='expanded'] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a {
      padding-left: 15px;
    }

    .pcoded-navbar .pcoded-item {
      .pcoded-hasmenu {
        .pcoded-submenu {
          width: 80%;
          list-style: outside none none;
          margin: 15px auto;
          opacity: 0;
          visibility: hidden;
          position: absolute;

          > li {
            &:first-child {
              a {
                padding-top: 0;

                > .pcoded-mtext:after {
                  top: calc(50% - 8px);
                }
              }
            }

            &:last-child {
              a {
                padding-bottom: 0;

                > .pcoded-mtext:after {
                  top: 50%;
                }
              }
            }
          }
        }

        &.pcoded-trigger .pcoded-submenu {
          -webkit-transform-origin: 0 0;
          transform-origin: 10% 10%;
          -webkit-transition:
            -webkit-transform 0.2s,
            opacity 0.2s;
          transition:
            transform 0.2s,
            opacity 0.2s;
        }

        .pcoded-submenu {
          transform-style: preserve-3d;
          -webkit-transform-style: preserve-3d;
          transform: rotateX(-90deg);
          -webkit-transform: rotateX(-90deg);
        }

        &.pcoded-trigger > .pcoded-submenu {
          position: relative;
          opacity: 1;
          visibility: visible;
          transform: rotateX(0deg);
          -webkit-transform: rotateX(0deg);
        }
      }

      &[item-border='true'] {
        &[item-border-style='dashed'] li > a {
          border-bottom-style: dashed;
        }

        &[item-border-style='dotted'] li > a {
          border-bottom-style: dotted;
        }

        &[item-border-style='solid'] li > a {
          border-bottom-style: solid;
        }

        &[item-border-style='none'] li > a {
          border: none;
        }
      }

      &[item-border='false'] > li > a {
        border-bottom-width: 0;
      }

      &[item-border='true'] > li > a {
        border-bottom-width: 1px;
      }

      &[subitem-border='false'] .pcoded-hasmenu .pcoded-submenu li > a {
        border-bottom-width: 0;
      }

      &[subitem-border='true'] .pcoded-hasmenu .pcoded-submenu li {
        > a {
          border-bottom-width: 1px;
        }

        &:last-child {
          > a {
            border-bottom-width: 0;
          }
        }
      }

      li.pcoded-hasmenu {
        > a:after {
          float: right;
          font-style: normal;
          margin-left: 3px;
          font-size: 10px;
          position: absolute;
          right: 15px;
          top: 3px;
          font-family: 'themify';
          padding-top: 12px;
          transition: opacity 0.3s linear;
          -webkit-transition: opacity 0.3s linear;
          -ms-transition: opacity 0.3s linear;
          -moz-transition: opacity 0.3s linear;
          -o-transition: opacity 0.3s linear;
        }

        .pcoded-submenu li.pcoded-hasmenu > a:after {
          top: 0;
        }
      }

      li.pcoded-hasmenu {
        &[dropdown-icon='style1'] > a:after {
          content: '\e649';
        }

        &.pcoded-trigger[dropdown-icon='style1'] > a:after {
          content: '\e64b';
        }

        &[dropdown-icon='style2'] > a:after {
          content: '\e661';
        }

        &.pcoded-trigger[dropdown-icon='style2'] > a:after {
          content: '\e663';
        }

        &[dropdown-icon='style3'] > a:after {
          content: '\e61a';
        }

        &.pcoded-trigger[dropdown-icon='style3'] > a:after {
          content: '\e622';
        }
      }
    }

    .pcoded-header .pcoded-left-header {
      &[pcoded-lheader-position='relative'] {
        position: relative;
      }

      &[pcoded-lheader-position='fixed'] {
        position: fixed;
      }

      &[pcoded-lheader-position='absolute'] {
        position: absolute;
      }
    }

    .pcoded-navbar {
      &[pcoded-navbar-position='relative'] {
        position: relative;
      }

      &[pcoded-navbar-position='fixed'] {
        position: fixed;
      }

      &[pcoded-navbar-position='absolute'] {
        position: absolute;
      }
    }

    .pcoded-header {
      &[pcoded-header-position='relative'] {
        position: relative;
      }

      &[pcoded-header-position='fixed'] {
        position: fixed;
        z-index: 1028;
      }

      &[pcoded-header-position='absolute'] {
        position: absolute;
      }
    }

    &[vertical-layout='wide'] .pcoded-container {
      width: 100%;
      margin: 0 auto;
    }

    &[vertical-layout='box'] {
      #styleSelector,
      .p-chat-user,
      .showChat_inner {
        position: absolute;
      }

      #styleSelector {
        top: 0;
      }
    }

    &[pcoded-device-type='desktop'][vertical-layout='box'] .pcoded-container {
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;

      .pcoded-header {
        max-width: 1200px;
      }
    }

    &[pcoded-device-type='tablet'][vertical-layout='box'] .pcoded-container {
      max-width: 900px;
      width: 100%;
      margin: 0 auto;
    }

    &[pcoded-device-type='phone'][vertical-layout='box'] .pcoded-container {
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
    }

    &[vertical-layout='widebox'] .pcoded-container {
      width: 100%;
      margin: 0 auto;
    }

    &[pcoded-device-type='desktop'][vertical-layout='widebox'] {
      .pcoded-main-container,
      .pcoded-wrapper {
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
      }
    }

    &[pcoded-device-type='tablet'][vertical-layout='widebox'] {
      .pcoded-main-container,
      .pcoded-wrapper {
        max-width: 900px;
        width: 100%;
        margin: 0 auto;
      }
    }

    &[pcoded-device-type='phone'][vertical-layout='widebox'] {
      .pcoded-main-container,
      .pcoded-wrapper {
        max-width: 100%;
        width: 100%;
        margin: 0 auto;
      }
    }

    &[vertical-layout='widebox'] .pcoded-wrapper {
      overflow: hidden;
    }
  }

  .pcoded-overlay-box {
    background: #000 none repeat scroll 0 0;
    bottom: 0;
    height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s linear;
    -webkit-transition: opacity 0.3s linear;
    -ms-transition: opacity 0.3s linear;
    -moz-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
    overflow: hidden;
  }

  &[vertical-nav-type='compact'][vertical-effect='overlay'] .pcoded-overlay-box,
  &[vertical-nav-type='ex-popover'][vertical-effect='overlay'] .pcoded-overlay-box,
  &[vertical-nav-type='expanded'][vertical-effect='overlay'] .pcoded-overlay-box,
  &[vertical-nav-type='fullsub-collapsed'][vertical-effect='overlay'] .pcoded-overlay-box,
  &[vertical-nav-type='sub-expanded'][vertical-effect='overlay'] .pcoded-overlay-box {
    visibility: visible;
    z-index: 1026;
    opacity: 0.3;
    transition: opacity 0.3s linear;
    -webkit-transition: opacity 0.3s linear;
    -ms-transition: opacity 0.3s linear;
    -moz-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
    overflow: hidden;
  }

  .pcoded-navbar .pcoded-item .pcoded-hasmenu {
    .pcoded-submenu li > a {
      .pcoded-micon {
        display: none;
        font-size: 10px;
        padding-right: 5px;
      }

      .pcoded-mtext:before {
        font-style: normal;
        padding-right: 4px;
        font-size: 10px;
        position: relative;
        left: 0;
        top: 0;
        opacity: 1;
        visibility: visible;
      }
    }

    &[subitem-icon='style1'] .pcoded-submenu li > a .pcoded-mtext:before {
      content: '\f105';
      font-family: FontAwesome;
      padding-right: 5px;
      font-size: 12px;
    }

    &[subitem-icon='style2'] .pcoded-submenu li > a .pcoded-mtext:before {
      content: '\f22d';
      font-family: FontAwesome;
      padding-right: 5px;
      font-size: 12px;
    }

    &[subitem-icon='style3'] .pcoded-submenu li > a .pcoded-mtext:before {
      content: '\e08d';
      font-family: simple-line-icons;
    }

    &[subitem-icon='style4'] .pcoded-submenu li > a .pcoded-mtext:before {
      content: '\f124';
      font-family: FontAwesome;
      padding-right: 10px;
      font-size: 12px;
    }

    &[subitem-icon='style5'] .pcoded-submenu li > a .pcoded-mtext:before {
      content: '\f0da';
      font-family: FontAwesome;
      padding-right: 10px;
      font-size: 12px;
    }

    &[subitem-icon='style6'] .pcoded-submenu li > a .pcoded-mtext:before {
      content: '\e649';
      font-family: 'themify';
      padding-right: 10px;
      font-size: 8px;
    }

    &[subitem-icon='style7'] {
      .pcoded-submenu {
        border-left: 1px solid #d6d6d6;

        > li {
          > a > .pcoded-mtext {
            &:after {
              content: '';
              width: 8px;
              height: 8px;
              border-radius: 50%;
              position: absolute;
              left: -4px;
              top: calc(50% - 4px);
              background-color: transparent;
            }
          }
        }
      }

      .pcoded-hasmenu {
        > li {
          > a {
            &:after {
              // content: "";
              // width: 8px;
              // height: 8px;
              // border-radius: 50%;
              // position: absolute;
              // left: -4px;
              // top: calc(50% - 4px);
              // background-color: transparent;
            }

            &:before {
              // content: "";
              // width: 8px;
              // height: 8px;
              // border-radius: 50%;
              // position: absolute;
              // left: -4px;
              // top: calc(50% - 4px);
              // background-color: transparent;
            }
          }
        }
      }
    }
  }

  &[theme-layout='vertical'] .pcoded-navbar .pcoded-item > li {
    &.active > a > .pcoded-mcaret {
      background: transparent none repeat scroll 0 0;
      border-bottom: 0 solid transparent;
      border-right: 0 solid #fff;
      border-top: 0 solid transparent;
      display: block;
      float: right;
      height: 0;
      position: absolute;
      right: 0;
      top: calc(50% - 10px);
      width: 0;
    }

    &:hover > a:before {
      border-left-style: solid;
      border-left-width: 5px;
      content: ' ';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover > a:before {
    border-left-style: solid;
    border-left-width: 5px;
    content: ' ';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
  }

  &[theme-layout='vertical'] {
    .pcoded-navbar {
      .pcoded-item > li.active > a:before {
        border-left-style: solid;
        border-left-width: 5px;
        content: ' ';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
      }
    }

    &[vertical-placement='right'] {
      .pcoded-navbar {
        float: right;
        right: 0;

        .main-menu-content,
        .main-menu-header {
          direction: rtl;
        }

        .main-menu-content {
          ul {
            padding-right: 0;
          }
        }
      }

      .pcoded-content {
        float: unset;
        left: 0;
      }
      .breadcrumb-item {
        float: right;
      }
    }

    &[vertical-placement='left'] {
      .pcoded-navbar {
        float: left;
      }

      .pcoded-content {
        float: unset;
        right: 0;
      }
    }

    &[vertical-placement='right'] .pcoded-header {
      .pcoded-left-header {
        float: right;
        right: 0;
      }

      .pcoded-right-header {
        float: unset;
        left: unset;
      }
    }

    &[vertical-placement='left'] .pcoded-header {
      .pcoded-left-header {
        float: left;
      }

      .pcoded-right-header {
        float: unset;
        right: unset;
      }
    }

    &[vertical-nav-type='expanded'] {
      .pcoded-header .pcoded-left-header,
      .pcoded-navbar {
        width: 235px;
      }
    }

    &[vertical-placement='left'][vertical-nav-type='expanded'][vertical-effect='push'] {
      .pcoded-header {
        .pcoded-right-header {
          margin-left: 235px;
        }

        &.iscollapsed .pcoded-right-header {
          margin-left: 235px;
          margin-right: -235px;
        }
      }

      .pcoded-content {
        margin-left: 235px;
        margin-right: -235px;
      }
    }

    &[vertical-placement='right'][vertical-nav-type='expanded'][vertical-effect='push'] {
      .pcoded-header {
        .pcoded-right-header {
          margin-right: 235px;
        }

        &.iscollapsed .pcoded-right-header {
          margin-right: 0;
          margin-left: -235px;
        }
      }

      .pcoded-content {
        margin-right: 235px;
        margin-left: -235px;
      }
    }

    &[vertical-placement='left'][vertical-nav-type='expanded'][vertical-effect='shrink'] {
      .pcoded-header {
        &.iscollapsed .pcoded-right-header,
        .pcoded-right-header {
          margin-left: 235px;
        }
      }

      .pcoded-content {
        margin-left: 235px;
      }
    }

    &[vertical-placement='right'][vertical-nav-type='expanded'][vertical-effect='shrink'] {
      .pcoded-header {
        &.iscollapsed .pcoded-right-header,
        .pcoded-right-header {
          margin-right: 235px;
        }
      }

      .pcoded-content {
        margin-right: 235px;
      }
    }

    &[vertical-placement='left'][vertical-nav-type='expanded'][vertical-effect='overlay'] {
      .pcoded-header {
        .pcoded-right-header {
          margin-left: 235px;
        }

        &.iscollapsed .pcoded-right-header {
          margin-left: 0;
        }
      }

      .pcoded-content {
        margin-left: 0;
      }
    }

    &[vertical-placement='right'][vertical-nav-type='expanded'][vertical-effect='overlay'] {
      .pcoded-header {
        .pcoded-right-header {
          margin-right: 235px;
        }

        &.iscollapsed .pcoded-right-header {
          margin-right: 0;
        }
      }

      .pcoded-content {
        margin-right: 0;
      }
    }

    &[vertical-effect='overlay'] .pcoded-navbar .pcoded-item > li.active > a > .pcoded-mcaret {
      background: transparent !important;
      border-bottom: transparent !important;
      border-right: transparent !important;
      border-top: 15px solid transparent;
    }

    &[vertical-nav-type='expanded'] {
      &[pcoded-device-type='desktop']
        .pcoded-navbar
        .pcoded-item
        > .pcoded-hasmenu.is-hover.pcoded-trigger
        .pcoded-submenu,
      &[pcoded-device-type='desktop']
        .pcoded-navbar.is-hover
        .pcoded-item
        > .pcoded-hasmenu.pcoded-trigger
        > .pcoded-submenu,
      &[pcoded-device-type='tablet']
        .pcoded-navbar
        .pcoded-item
        > .pcoded-hasmenu.is-hover.pcoded-trigger
        .pcoded-submenu,
      &[pcoded-device-type='tablet']
        .pcoded-navbar.is-hover
        .pcoded-item
        > .pcoded-hasmenu.pcoded-trigger
        > .pcoded-submenu {
        left: 100%;
        position: absolute;
        width: 235px;
        z-index: 1024;
        top: 0;
      }
    }

    &[vertical-nav-type='collapsed'] {
      .pcoded-header .pcoded-left-header {
        width: 235px;
      }

      .pcoded-navbar {
        width: 56px;

        .pcoded-item > li > a {
          padding: 10px 13px;
        }
      }
    }

    &[vertical-placement='left'][vertical-nav-type='collapsed'][vertical-effect='push'] {
      .pcoded-header {
        .pcoded-right-header {
          margin-left: 235px;
        }

        &.iscollapsed {
          .pcoded-left-header {
            width: 56px;
          }

          .pcoded-right-header {
            margin-left: 56px;
            margin-right: 0;
          }
        }
      }

      .pcoded-content {
        margin-left: 56px;
        margin-right: 0;
      }
    }

    &[vertical-placement='right'][vertical-nav-type='collapsed'][vertical-effect='push'] {
      .pcoded-header {
        .pcoded-right-header {
          margin-right: 235px;
        }

        &.iscollapsed {
          .pcoded-left-header {
            width: 56px;
          }

          .pcoded-right-header {
            margin-right: 45px;
            margin-left: 0;
          }
        }
      }

      .pcoded-content {
        margin-right: 56px;
        margin-left: 0;
      }
    }

    &[vertical-placement='left'][vertical-nav-type='collapsed'][vertical-effect='shrink'] {
      .pcoded-header {
        .pcoded-right-header {
          margin-left: 235px;
        }

        &.iscollapsed {
          .pcoded-left-header {
            width: 56px;
          }

          .pcoded-right-header {
            margin-left: 56px;
          }
        }
      }

      .pcoded-content {
        margin-left: 56px;
      }
    }

    &[vertical-placement='right'][vertical-nav-type='collapsed'][vertical-effect='shrink'] {
      .pcoded-header {
        .pcoded-right-header {
          margin-right: 235px;
        }

        &.iscollapsed {
          .pcoded-left-header {
            width: 56px;
          }

          .pcoded-right-header {
            margin-right: 56px;
          }
        }
      }

      .pcoded-content {
        margin-right: 56px;
      }
    }

    &[vertical-placement='left'][vertical-nav-type='collapsed'][vertical-effect='overlay'] {
      .pcoded-header {
        .pcoded-right-header {
          margin-left: 235px;
        }

        &.iscollapsed {
          .pcoded-left-header {
            width: 56px;
          }

          .pcoded-right-header {
            margin-left: 56px;
          }
        }
      }

      .pcoded-content {
        margin-left: 56px;
      }
    }

    &[vertical-placement='right'][vertical-nav-type='collapsed'][vertical-effect='overlay'] {
      .pcoded-header {
        .pcoded-right-header {
          margin-right: 235px;
        }

        &.iscollapsed {
          .pcoded-left-header {
            width: 56px;
          }

          .pcoded-right-header {
            margin-right: 56px;
          }
        }
      }

      .pcoded-content {
        margin-right: 56px;
      }
    }

    .pcoded-navbar .pcoded-item {
      > li > a .pcoded-mtext {
        opacity: 1;
        visibility: visible;
        position: absolute;
        top: 9px;
      }

      li.pcoded-hasmenu > a:after {
        opacity: 1;
        visibility: visible;
      }
    }

    &[vertical-nav-type='collapsed'] .pcoded-navbar .pcoded-item {
      > li {
        > a .pcoded-mtext {
          opacity: 0;
          visibility: hidden;
          position: absolute;
        }

        &.pcoded-trigger > a .pcoded-mtext {
          opacity: 1;
          visibility: visible;
          position: absolute;
          padding-left: 22px;
        }
      }

      li.pcoded-hasmenu {
        > a:after {
          opacity: 0;
          visibility: hidden;
        }

        &.pcoded-trigger a:after {
          opacity: 1;
          visibility: visible;
        }
      }

      > {
        li.pcoded-trigger {
          display: block;
          position: relative;
          width: 290px;
          z-index: 1024;
          border-right: none;
          // box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.13);
        }

        .pcoded-hasmenu {
          &.pcoded-trigger > .pcoded-submenu {
            left: 56px;
            position: absolute;
            width: 210px;
            z-index: 1024;
            border-left-style: solid;
            border-left-width: 1px;

            .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
              position: relative;
            }
          }

          > .pcoded-submenu .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
            position: absolute;
          }
        }

        li.pcoded-trigger.active > a > .pcoded-mcaret {
          display: none;
        }
      }
    }

    &[vertical-placement='left'][vertical-nav-type='offcanvas'][vertical-effect='push'] {
      .pcoded-header {
        .pcoded-left-header {
          width: 235px;
        }

        .pcoded-right-header {
          margin-left: 235px;
        }

        &.iscollapsed {
          .pcoded-left-header {
            width: 0;
          }

          .pcoded-right-header {
            margin-left: 0;
            margin-right: 0;
          }
        }
      }

      .pcoded-navbar {
        width: 235px;
        margin-left: -250px;
      }

      .pcoded-content {
        margin-left: 0;
        margin-right: 0;
      }
    }

    &[vertical-placement='right'][vertical-nav-type='offcanvas'][vertical-effect='push'] {
      .pcoded-header {
        .pcoded-left-header {
          width: 235px;
        }

        .pcoded-right-header {
          margin-right: 0;
        }

        &.iscollapsed {
          .pcoded-left-header {
            width: 0;
          }

          .pcoded-right-header {
            margin-right: 0;
            margin-left: 0;
          }
        }
      }

      .pcoded-navbar {
        width: 235px;
        margin-right: -250px;
      }

      .pcoded-content {
        margin-right: 0;
        margin-left: 0;
      }
    }

    &[vertical-placement='left'][vertical-nav-type='offcanvas'][vertical-effect='shrink'] {
      .pcoded-header {
        .pcoded-left-header {
          width: 235px;
        }

        .pcoded-right-header {
          margin-left: 235px;
        }

        &.iscollapsed {
          .pcoded-left-header {
            width: 0;
          }

          .pcoded-right-header {
            margin-left: 0;
          }
        }
      }

      .pcoded-navbar {
        width: 235px;
        margin-left: -250px;
      }

      .pcoded-content {
        margin-left: 0;
      }
    }

    &[vertical-placement='right'][vertical-nav-type='offcanvas'][vertical-effect='shrink'] {
      .pcoded-header {
        .pcoded-left-header {
          width: 235px;
        }

        .pcoded-right-header {
          margin-right: 235px;
        }

        &.iscollapsed {
          .pcoded-left-header {
            width: 0;
          }

          .pcoded-right-header {
            margin-right: 0;
          }
        }
      }

      .pcoded-navbar {
        width: 235px;
        margin-right: -235px;
      }

      .pcoded-content {
        margin-right: 0;
      }
    }

    &[vertical-placement='left'][vertical-nav-type='offcanvas'][vertical-effect='overlay'] {
      .pcoded-header {
        .pcoded-left-header {
          width: 235px;
        }

        .pcoded-right-header {
          margin-left: 235px;
        }

        &.iscollapsed {
          .pcoded-left-header {
            width: 0;
          }

          .pcoded-right-header {
            margin-left: 0;
          }
        }
      }

      .pcoded-navbar {
        width: 235px;
        margin-left: -250px;
      }

      .pcoded-content {
        margin-left: 0;
      }
    }

    &[vertical-placement='right'][vertical-nav-type='offcanvas'][vertical-effect='overlay'] {
      .pcoded-header {
        .pcoded-left-header {
          width: 235px;
        }

        .pcoded-right-header {
          margin-right: 235px;
        }

        &.iscollapsed {
          .pcoded-left-header {
            width: 0;
          }

          .pcoded-right-header {
            margin-right: 0;
          }
        }
      }

      .pcoded-navbar {
        width: 235px;
        margin-right: -235px;
      }

      .pcoded-content {
        margin-right: 0;
      }
    }

    &[vertical-placement='left'][vertical-nav-type='compact'][vertical-effect='push'] {
      .pcoded-header {
        &.iscollapsed {
          .pcoded-left-header {
            width: 200px;
          }

          .pcoded-right-header {
            margin-left: 200px;
            margin-right: -200px;
          }
        }

        .pcoded-left-header {
          width: 200px;
        }

        .pcoded-right-header {
          margin-left: 200px;
        }
      }

      .pcoded-navbar {
        width: 200px;
      }

      .pcoded-content {
        margin-left: 200px;
        margin-right: -200px;
      }
    }

    &[vertical-placement='right'][vertical-nav-type='compact'][vertical-effect='push'] {
      .pcoded-header {
        &.iscollapsed {
          .pcoded-left-header {
            width: 200px;
          }

          .pcoded-right-header {
            margin-right: 200px;
            margin-left: -200px;
          }
        }

        .pcoded-left-header {
          width: 200px;
        }

        .pcoded-right-header {
          margin-right: 200px;
        }
      }

      .pcoded-navbar {
        width: 200px;
      }

      .pcoded-content {
        margin-right: 200px;
        margin-left: -200px;
      }
    }

    &[vertical-placement='left'][vertical-nav-type='compact'][vertical-effect='shrink'] {
      .pcoded-header {
        &.iscollapsed {
          .pcoded-left-header {
            width: 200px;
          }

          .pcoded-right-header {
            margin-left: 200px;
          }
        }

        .pcoded-left-header {
          width: 200px;
        }

        .pcoded-right-header {
          margin-left: 200px;
        }
      }

      .pcoded-navbar {
        width: 200px;
      }

      .pcoded-content {
        margin-left: 200px;
      }
    }

    &[vertical-placement='right'][vertical-nav-type='compact'][vertical-effect='shrink'] {
      .pcoded-header {
        &.iscollapsed {
          .pcoded-left-header {
            width: 200px;
          }

          .pcoded-right-header {
            margin-right: 200px;
          }
        }

        .pcoded-left-header {
          width: 200px;
        }

        .pcoded-right-header {
          margin-right: 200px;
        }
      }

      .pcoded-navbar {
        width: 200px;
      }

      .pcoded-content {
        margin-right: 200px;
      }
    }

    &[vertical-placement='left'][vertical-nav-type='compact'][vertical-effect='overlay'] {
      .pcoded-header {
        &.iscollapsed {
          .pcoded-left-header {
            width: 200px;
          }

          .pcoded-right-header {
            margin-left: 0;
          }
        }

        .pcoded-left-header {
          width: 200px;
        }

        .pcoded-right-header {
          margin-left: 200px;
        }
      }

      .pcoded-navbar {
        width: 200px;
      }

      .pcoded-content {
        margin-left: 0;
      }
    }

    &[vertical-placement='right'][vertical-nav-type='compact'][vertical-effect='overlay'] {
      .pcoded-header {
        .pcoded-left-header {
          width: 200px;
        }

        .pcoded-right-header {
          margin-right: 200px;
        }

        &.iscollapsed {
          .pcoded-left-header {
            width: 200px;
          }

          .pcoded-right-header {
            margin-right: 0;
          }
        }
      }

      .pcoded-navbar {
        width: 200px;
      }

      .pcoded-content {
        margin-right: 0;
      }
    }

    &[vertical-nav-type='compact'] {
      .main-menu-content,
      .main-menu-header {
        display: none;
      }

      .pcoded-navbar .pcoded-item > li {
        > a {
          line-height: 1;
          text-align: center;
          padding: 10px 20px 20px;

          .pcoded-mtext {
            opacity: 1;
            position: relative;
            text-align: center;
            visibility: visible;
            top: 10px;
          }

          > .pcoded-micon {
            display: block;
            font-size: 18px;
            padding: 5px;
            margin: 0 auto;
          }
        }

        &.active > a {
          > .pcoded-mcaret {
            background: transparent none repeat scroll 0 0;
            border-bottom: 0 solid transparent;
            border-right: 0 solid #e9e9e9;
            border-top: 0 solid transparent;
            top: 18px;
          }

          &:before {
            height: 100%;
          }
        }

        &:hover > a:before {
          height: 100%;
        }

        &.pcoded-hasmenu {
          &.pcoded-trigger[dropdown-icon='style1'] > a:after,
          &[dropdown-icon='style1'] > a:after {
            top: 18px;
          }
        }
      }

      &[pcoded-device-type='desktop']
        .pcoded-navbar
        .pcoded-item
        > .pcoded-hasmenu.is-hover.pcoded-trigger
        .pcoded-submenu,
      &[pcoded-device-type='desktop']
        .pcoded-navbar.is-hover
        .pcoded-item
        > .pcoded-hasmenu.pcoded-trigger
        > .pcoded-submenu,
      &[pcoded-device-type='tablet']
        .pcoded-navbar
        .pcoded-item
        > .pcoded-hasmenu.is-hover.pcoded-trigger
        .pcoded-submenu,
      &[pcoded-device-type='tablet']
        .pcoded-navbar.is-hover
        .pcoded-item
        > .pcoded-hasmenu.pcoded-trigger
        > .pcoded-submenu {
        left: 100%;
        position: absolute;
        width: 210px;
        z-index: 1024;
        top: 0;
      }
    }

    &[vertical-nav-type='sub-expanded'] {
      .pcoded-header .pcoded-left-header,
      .pcoded-navbar {
        width: 235px;
      }
    }

    &[vertical-placement='left'][vertical-nav-type='sub-expanded'][vertical-effect='push'] {
      .pcoded-header {
        .pcoded-right-header {
          margin-left: 235px;
        }

        &.iscollapsed .pcoded-right-header {
          margin-left: 235px;
          margin-right: -235px;
        }
      }

      .pcoded-content {
        margin-left: 235px;
        margin-right: -235px;
      }
    }

    &[vertical-placement='right'][vertical-nav-type='sub-expanded'][vertical-effect='push'] {
      .pcoded-header {
        .pcoded-right-header {
          margin-right: 235px;
        }

        &.iscollapsed .pcoded-right-header {
          margin-right: 0;
          margin-left: -235px;
        }
      }

      .pcoded-content {
        margin-right: 235px;
        margin-left: -235px;
      }
    }

    &[vertical-placement='left'][vertical-nav-type='sub-expanded'][vertical-effect='shrink'] {
      .pcoded-header {
        &.iscollapsed .pcoded-right-header,
        .pcoded-right-header {
          margin-left: 235px;
        }
      }

      .pcoded-content {
        margin-left: 235px;
      }
    }

    &[vertical-placement='right'][vertical-nav-type='sub-expanded'][vertical-effect='shrink'] {
      .pcoded-header {
        &.iscollapsed .pcoded-right-header,
        .pcoded-right-header {
          margin-right: 235px;
        }
      }

      .pcoded-navbar {
        width: 235px;
      }

      .pcoded-content {
        margin-right: 235px;
      }
    }

    &[vertical-placement='left'][vertical-nav-type='sub-expanded'][vertical-effect='overlay'] {
      .pcoded-header {
        .pcoded-right-header {
          margin-left: 235px;
        }

        &.iscollapsed .pcoded-right-header {
          margin-left: 0;
        }
      }

      .pcoded-navbar {
        width: 235px;
      }

      .pcoded-content {
        margin-left: 0;
      }
    }

    &[vertical-placement='right'][vertical-nav-type='sub-expanded'][vertical-effect='overlay'] {
      .pcoded-header {
        .pcoded-right-header {
          margin-right: 235px;
        }

        &.iscollapsed .pcoded-right-header {
          margin-right: 0;
        }
      }

      .pcoded-content {
        margin-right: 0;
      }
    }

    &[vertical-nav-type='sub-expanded'] {
      &[pcoded-device-type='desktop'] .pcoded-inner-navbar,
      &[pcoded-device-type='desktop'] .pcoded-navbar .pcoded-item,
      &[pcoded-device-type='desktop'] .pcoded-navbar .pcoded-item > li,
      &[pcoded-device-type='tablet'] .pcoded-inner-navbar,
      &[pcoded-device-type='tablet'] .pcoded-navbar .pcoded-item,
      &[pcoded-device-type='tablet'] .pcoded-navbar .pcoded-item > li {
        position: unset;
      }

      &[pcoded-device-type='desktop'] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu,
      &[pcoded-device-type='tablet'] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
        left: 100%;
        position: absolute;
        width: 230px;
        z-index: 1024;
        top: 0;
        bottom: 0;
      }

      &[pcoded-device-type='desktop'] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li,
      &[pcoded-device-type='tablet'] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li {
        position: unset;
      }

      &[pcoded-device-type='desktop'] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger .pcoded-submenu,
      &[pcoded-device-type='tablet'] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger .pcoded-submenu {
        left: 100%;
        position: absolute;
        width: 230px;
        z-index: 1024;
        top: 0;
        bottom: 0;
      }

      .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
        border-left-style: solid;
        border-left-width: 1px;
      }
    }

    &[vertical-nav-type='ex-popover'] {
      .pcoded-header {
        &.iscollapsed .pcoded-left-header,
        .pcoded-left-header {
          width: 235px;
        }
      }

      .pcoded-navbar {
        width: 235px;
      }
    }

    &[vertical-placement='left'][vertical-nav-type='ex-popover'][vertical-effect='push'] {
      .pcoded-header {
        .pcoded-right-header {
          margin-left: 235px;
        }

        &.iscollapsed .pcoded-right-header {
          margin-left: 235px;
          margin-right: -235px;
        }
      }

      .pcoded-content {
        margin-left: 235px;
        margin-right: -235px;
      }
    }

    &[vertical-placement='right'][vertical-nav-type='ex-popover'][vertical-effect='push'] {
      .pcoded-header {
        .pcoded-right-header {
          margin-right: 235px;
        }

        &.iscollapsed .pcoded-right-header {
          margin-right: 0;
          margin-left: -235px;
        }
      }

      .pcoded-content {
        margin-right: 235px;
        margin-left: -235px;
      }
    }

    &[vertical-placement='left'][vertical-nav-type='ex-popover'][vertical-effect='shrink'] {
      .pcoded-header {
        &.iscollapsed .pcoded-right-header,
        .pcoded-right-header {
          margin-left: 235px;
        }
      }

      .pcoded-content {
        margin-left: 235px;
      }
    }

    &[vertical-placement='right'][vertical-nav-type='ex-popover'][vertical-effect='shrink'] {
      .pcoded-header {
        &.iscollapsed .pcoded-right-header,
        .pcoded-right-header {
          margin-right: 235px;
        }
      }

      .pcoded-content {
        margin-right: 235px;
      }
    }

    &[vertical-placement='left'][vertical-nav-type='ex-popover'][vertical-effect='overlay'] {
      .pcoded-header {
        .pcoded-right-header {
          margin-left: 235px;
        }

        &.iscollapsed .pcoded-right-header {
          margin-left: 0;
        }
      }

      .pcoded-content {
        margin-left: 0;
      }
    }

    &[vertical-placement='right'][vertical-nav-type='ex-popover'][vertical-effect='overlay'] {
      .pcoded-header {
        .pcoded-right-header {
          margin-right: 235px;
        }

        &.iscollapsed .pcoded-right-header {
          margin-right: 0;
        }
      }

      .pcoded-content {
        margin-right: 0;
      }
    }

    &[vertical-nav-type='ex-popover'] {
      &[pcoded-device-type='desktop'] .pcoded-navbar .pcoded-item > li,
      &[pcoded-device-type='tablet'] .pcoded-navbar .pcoded-item > li {
        position: relative;
      }

      &[pcoded-device-type='desktop'] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu,
      &[pcoded-device-type='tablet'] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
        left: 100%;
        position: absolute;
        width: 230px;
        z-index: 1024;
        top: 0;
      }

      &[pcoded-device-type='desktop'] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li,
      &[pcoded-device-type='tablet'] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li {
        position: relative;
      }

      &[pcoded-device-type='desktop'] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger .pcoded-submenu,
      &[pcoded-device-type='tablet'] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger .pcoded-submenu {
        left: 100%;
        position: absolute;
        width: 230px;
        z-index: 1024;
        top: 0;
      }

      &[pcoded-device-type='desktop'] .pcoded-navbar .pcoded-item li.pcoded-trigger > a > .pcoded-mcaret,
      &[pcoded-device-type='tablet'] .pcoded-navbar .pcoded-item li.pcoded-trigger > a > .pcoded-mcaret {
        background: transparent none repeat scroll 0 0;
        border-bottom: 19px solid transparent;
        border-right: 12px solid;
        border-top: 19px solid transparent;
        display: block;
        float: right;
        height: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 0;
        z-index: 1025;
      }

      .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
        border-left-style: solid;
        border-left-width: 1px;
      }
    }

    &[vertical-placement='left'][vertical-nav-type='fullpage'] .pcoded-header {
      .pcoded-left-header {
        width: 235px;
      }

      .pcoded-right-header {
        margin-left: 235px;
      }

      &.iscollapsed .pcoded-left-header {
        width: 235px;
      }
    }

    &[vertical-nav-type='fullpage'] {
      .pcoded-navbar {
        width: 100%;
      }

      .pcoded-content {
        margin-left: 0;
        margin-right: 0;
      }
    }

    &[vertical-placement='right'][vertical-nav-type='fullpage'] .pcoded-header {
      .pcoded-left-header {
        width: 235px;
      }

      .pcoded-right-header {
        margin-right: 235px;
      }

      &.iscollapsed .pcoded-left-header {
        width: 235px;
      }
    }

    &[vertical-nav-type='fullpage'] {
      .pcoded-navbar {
        .pcoded-item {
          > li.active > a {
            background: transparent !important;
          }

          &[item-border='true'] > li > a {
            border-bottom-width: 0 !important;
          }
        }
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1030;
        opacity: 1;
        visibility: visible;
        width: 100%;

        &[pcoded-navbar-position='absolute'] {
          position: absolute;
        }

        &[pcoded-navbar-position='fixed'] {
          position: fixed;
        }

        .pcoded-inner-navbar {
          margin-top: 40px;
        }
      }

      .pcoded-main-container {
        display: block;
        position: unset;
      }

      .pcoded-navbar {
        .pcoded-item > li > a {
          .pcoded-mtext {
            opacity: 1;
            position: relative;
            visibility: visible;
          }
          height: 45px;
          line-height: 45px;
          text-align: center;
          font-size: 24px;
          font-weight: 500;

          > .pcoded-micon {
            display: none;
          }
        }

        .pcoded-navigation-label {
          display: none !important;
        }

        .pcoded-item > li {
          &.active > a:before,
          a .pcoded-badge {
            display: none;
          }

          &:hover > a {
            background: transparent !important;

            &:before {
              display: none;
            }
          }

          &.active > a > .pcoded-mcaret {
            display: none;
          }

          > a {
            padding: 0;
          }
        }

        .sidebar_toggle {
          height: 30px;
        }
      }
    }
  }
}

.pcoded-navbar .sidebar_toggle a {
  opacity: 1;
  visibility: visible;
  transform: rotateX(90deg);
  -webkit-transform: rotateX(90deg);
  -ms-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  -o-transform: rotateX(90deg);
  position: absolute;
}

.pcoded[theme-layout='vertical'][vertical-nav-type='fullpage'] .pcoded-navbar .sidebar_toggle a {
  color: #f1f1f1;
  float: right;
  font-size: 36px;
  opacity: 1;
  position: relative;
  right: 10px;
  text-decoration: none;
  top: 0;
  transition: opacity 0.8s linear 0s;
  -webkit-transition: opacity 0.8s linear 0s;
  -ms-transition: opacity 0.8s linear 0s;
  -moz-transition: opacity 0.8s linear 0s;
  -o-transition: opacity 0.8s linear 0s;
  visibility: visible;
  transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
}

.pcoded-navbar .sidebar_toggle a:hover {
  color: #fff;
}

.pcoded {
  &[theme-layout='vertical'] {
    &[vertical-nav-type='fullpage'] .pcoded-navbar .pcoded-item {
      > li {
        &.pcoded-hasmenu > a:after {
          display: none;
        }

        &.pcoded-trigger > a {
          background: transparent !important;
          border-bottom-color: transparent !important;
        }
      }

      .pcoded-hasmenu .pcoded-submenu {
        background: transparent !important;
      }

      &[subitem-border='true'] .pcoded-hasmenu .pcoded-submenu li > a {
        border-bottom-width: 0;
      }

      .pcoded-hasmenu .pcoded-submenu li {
        > a {
          text-align: center;

          .pcoded-mtext:before {
            display: none;
          }
          font-size: 24px;
          font-weight: 500;
        }

        &:hover > a {
          background: transparent !important;

          &:before {
            border-left-width: 0;
          }
        }
      }

      .pcoded-submenu > li.active > a {
        background: transparent !important;
        color: #fff !important;
      }
    }

    &[pcoded-device-type='phone'] {
      .pcoded-header.nocollapsed .pcoded-left-header {
        width: 100% !important;
        padding-left: 235px;
      }

      &[vertical-nav-type='collapsed'] .pcoded-header.nocollapsed .pcoded-left-header {
        padding-left: 45px;
      }

      &[vertical-effect='overlay'] .pcoded-header.nocollapsed .pcoded-left-header,
      &[vertical-nav-type='fullpage'] .pcoded-header.nocollapsed .pcoded-left-header,
      &[vertical-nav-type='offcanvas'] .pcoded-header.nocollapsed .pcoded-left-header {
        padding-left: 0;
      }

      .pcoded-header.nocollapsed {
        height: 100px !important;

        .pcoded-right-header {
          padding: 50px 5px 0 !important;
        }
      }

      &[vertical-effect='overlay'] .pcoded-header.nocollapsed .pcoded-right-header,
      &[vertical-nav-type='offcanvas'] .pcoded-header.nocollapsed .pcoded-right-header {
        margin: 0 !important;
      }

      .pcoded-header.nocollapsed .sidebar_toggle a {
        background: transparent none repeat scroll 0 0;
        border-radius: 4px;
        color: #fff;
        float: left;
        font-size: 18px;
        height: 35px;
        margin-right: 5px;
        position: fixed;
        right: 0;
        text-align: center;
        top: 6px;
        width: 40px;
        z-index: 1028;
      }
    }
  }

  &.nocollapsed[theme-layout='vertical'][pcoded-device-type='phone'] {
    .pcoded-navbar {
      top: 0;
      z-index: 1030;
    }

    .pcoded-main-container {
      position: unset;
    }

    #styleSelector {
      top: 100px;
    }
  }

  .pcoded-navbar {
    .pcoded-navigation-label {
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 1px;
      opacity: 1;
      padding: 10px 15px;
      // text-transform: lowercase;
      visibility: visible;
      width: 100%;
      transform: rotateX(0deg);
      transition: opacity 0.3s linear;
      border-top: 1px solid transparentize($theme-border, 0.5);

      &:before {
        // content: "\eeb6";
        // font: {
        //     family: 'icofont';
        //     size: 12px;
        // }
        // position: absolute;
        // left: 20px;
        // top: 21px;
      }
      @each $value in $menu-title {
        $i: index($menu-title, $value);

        &[menu-title-theme='theme#{$i}'] {
          color: $value;
        }
      }
    }

    .pcoded-search + .pcoded-navigation-label {
      border-top: 0 solid transparent;
    }
  }

  &[vertical-nav-type='collapsed'] .pcoded-navbar .pcoded-navigation-label {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    transform: rotateX(90deg);
    -webkit-transform: rotateX(90deg);
    -ms-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    -o-transform: rotateX(90deg);
    transition: opacity 0.3s linear;
    -webkit-transition: opacity 0.3s linear;
    -ms-transition: opacity 0.3s linear;
    -moz-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
  }

  &[vertical-nav-type='compact'] .pcoded-navbar .pcoded-navigation-label {
    display: none;
  }

  .pcoded-navbar .pcoded-item {
    li a .pcoded-badge {
      position: absolute;
      right: 30px;
      text-align: center;
      top: 10px;
      vertical-align: middle;
      text-transform: lowercase;
      white-space: nowrap;
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s linear;
    }

    .pcoded-submenu > li a .pcoded-badge {
      top: 10px;
      right: 2px;
    }
  }

  &[vertical-nav-type='collapsed'] .pcoded-navbar .pcoded-item > li {
    > a .pcoded-badge {
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s linear;
      -webkit-transition: opacity 0.3s linear;
      -ms-transition: opacity 0.3s linear;
      -moz-transition: opacity 0.3s linear;
      -o-transition: opacity 0.3s linear;
    }

    &.pcoded-trigger > a .pcoded-badge {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s linear;
      -webkit-transition: opacity 0.3s linear;
      -ms-transition: opacity 0.3s linear;
      -moz-transition: opacity 0.3s linear;
      -o-transition: opacity 0.3s linear;
    }
  }

  &[theme-layout='vertical'][vertical-placement='right'] {
    .pcoded-navbar {
      .pcoded-item {
        > li {
          &.active > a {
            &:before {
              left: auto;
              right: 0;
            }

            > .pcoded-mcaret {
              background: transparent none repeat scroll 0 0;
              border-bottom: 0 solid transparent;
              border-left: 0 solid #e9e9e9;
              border-top: 0 solid transparent;
              left: 0;
              right: auto;
              top: 4px;
              border-right: 0;
            }
          }

          > a .pcoded-micon i {
            //                        float: right;
            //                        padding-right: 17px;
            //                        position: absolute;
            //                        right: 0;
            //                        top: 11px;
          }

          &.pcoded-hasmenu > a:after {
            left: 8px;
            right: auto;
            top: -1px;
          }

          > a .pcoded-mtext {
            left: auto;
            right: 0;
            padding-right: 60px;
          }
        }

        li a .pcoded-badge {
          position: relative;
          right: 30px;
          top: -5px;
        }

        > li:hover > a:before {
          left: auto;
          right: 0;
        }

        .pcoded-hasmenu .pcoded-submenu li {
          &:hover > a:before {
            left: auto;
            right: 0;
          }

          > a {
            .pcoded-mtext:before {
              float: right;
              left: 6px;
              top: 3px;
              padding-right: 10px;
            }
            text-align: right;
          }
        }

        li .pcoded-submenu li > a .pcoded-badge {
          left: 8px;
          position: absolute;
          right: auto;
          top: 11px;
        }
      }

      .pcoded-navigation-label {
        text-align: right;
        padding: 10px 40px 2px 20px;

        &:before {
          left: auto;
          right: 20px;
        }
      }

      .pcoded-item li.pcoded-hasmenu {
        &[dropdown-icon='style1'] > a:after {
          content: '\eb8a';
          font-family: 'icofont';
        }

        &.pcoded-trigger[dropdown-icon='style1'] > a:after {
          content: '\eb89';
          font-family: 'icofont';
        }
      }
    }

    &[vertical-nav-type='expanded'] {
      &[pcoded-device-type='desktop']
        .pcoded-navbar
        .pcoded-item
        > .pcoded-hasmenu.is-hover.pcoded-trigger
        .pcoded-submenu,
      &[pcoded-device-type='desktop']
        .pcoded-navbar.is-hover
        .pcoded-item
        > .pcoded-hasmenu.pcoded-trigger
        > .pcoded-submenu,
      &[pcoded-device-type='tablet']
        .pcoded-navbar
        .pcoded-item
        > .pcoded-hasmenu.is-hover.pcoded-trigger
        .pcoded-submenu,
      &[pcoded-device-type='tablet']
        .pcoded-navbar.is-hover
        .pcoded-item
        > .pcoded-hasmenu.pcoded-trigger
        > .pcoded-submenu {
        left: auto;
        right: 100%;
      }
    }

    .pcoded-navbar .pcoded-item > li.pcoded-hasmenu.pcoded-trigger .pcoded-submenu li.pcoded-hasmenu > a:after {
      left: 7px;
      right: auto;
      top: 0;
    }

    &[vertical-nav-type='collapsed'] .pcoded-navbar .pcoded-item {
      > {
        li.pcoded-trigger {
          left: auto;
          right: 210px;
        }

        .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
          left: auto;
        }
      }

      li a .pcoded-badge {
        position: absolute;
        right: auto;
        top: 10px;
      }
    }

    .pcoded-header {
      .pcoded-right-header {
        .pcoded-rl-header {
          float: right;
        }

        .pcoded-rr-header {
          float: left;
        }
      }

      .sidebar_toggle {
        float: right;
      }
    }

    &[vertical-nav-type='collapsed'] .pcoded-search.open .pcoded-search-box {
      right: 0;
    }
  }

  &[theme-layout='vertical'][vertical-placement='right'] {
    .pcoded-navbar {
      .pcoded-item {
        .pcoded-hasmenu .pcoded-submenu li {
          > a {
            .pcoded-mtext:before {
              padding-left: 10px;
              transform: rotate(180deg);
            }
            text-align: right;
          }
        }
      }
    }
  }
}

body {
  @each $value in $box-bg-color {
    $i: index($box-bg-color, $value);

    &[themebg-pattern='pattern#{$i}'] {
      background-color: $value;
    }
  }
}

.pcoded {
  .pcoded-navbar {
    @each $value in $left-navbar-theme {
      $i: index($left-navbar-theme, $value);

      .pcoded-header {
        &[header-theme='theme#{$i}'] {
          color: #fff;

          .sidebar_toggle a {
            background: transparent;
            color: #fff;
            border-color: #fff;
          }
        }

        &.nocollapsed .pcoded-left-header[lheader-theme='theme#{$i}'] {
          background: transparent;
        }

        &.iscollapsed .pcoded-left-header[lheader-theme='theme#{$i}'] {
          background: $value;
        }
      }

      &[navbar-theme='theme#{$i}'] {
        // background: $value;
        .main-menu {
          background-color: $value;

          .main-menu-header {
            background-color: $value;

            .user-details span {
              color: #fff;
            }
          }

          .main-menu-content {
            .more-details a {
              color: #fff;
            }
          }
        }

        .pcoded-item {
          > li {
            > a {
              color: #fff;

              .pcoded-micon {
                color: #fff;
              }
            }

            &.active > a {
              // background: $value;
              color: #fff;
              // &:before {
              //     border-left-color: $value;
              // }
              border-bottom-color: $value;
            }

            > a {
              border-bottom-color: $value;
            }
          }

          li.pcoded-hasmenu .pcoded-submenu {
            background: $value;

            li > a {
              border-bottom-color: lighten($value, 10%);
              color: #fff;
            }
          }

          > li {
            &.pcoded-hasmenu {
              .pcoded-submenu li.pcoded-hasmenu > a:after,
              > a:after {
                color: #fff;
              }

              &.pcoded-trigger {
                .pcoded-submenu li.pcoded-hasmenu.pcoded-trigger > a:after,
                > a:after {
                  color: #fff;
                }
              }
            }

            &.active:hover > a,
            &:hover > a {
              // background: $value;
              color: #fff;
            }
          }

          .pcoded-hasmenu .pcoded-submenu li {
            &:hover {
              > a {
                background-color: $value;
                color: #fff;
              }

              > a:before {
                border-left-color: transparent;
              }
            }

            &.active {
              > a {
                color: #fff;
                background-color: $value;
              }

              > a .pcoded-mtext:before {
                border-bottom-color: $value;
              }

              > a .pcoded-mtext:after {
                border-left-color: $value;
              }
            }

            > a .pcoded-mtext:before {
              border-bottom-color: #fff;
            }

            > a .pcoded-mtext:after {
              border-left-color: #fff;
            }
          }

          > li {
            &:hover > a:before {
              border-left-color: $value;
            }

            &.pcoded-hasmenu:hover > a:after {
              color: #fff;
            }

            &.pcoded-trigger > a {
              // background: $value;
              border-bottom-color: $value;
            }
          }

          > li {
            // &.pcoded-trigger.active > a {
            //     background: $value;
            // }

            &.pcoded-hasmenu .pcoded-submenu li.pcoded-hasmenu:hover > a:after {
              color: #fff;
            }
          }

          // > li.pcoded-hasmenu.pcoded-trigger.active > a:after {
          //     color: $value;
          // }
        }
        // .pcoded-navigation-label {
        //     color: $value;
        // }
      }

      &[theme-layout='vertical'] {
        &[vertical-nav-type='collapsed']
          .pcoded-navbar[navbar-theme='theme#{$i}']
          .pcoded-item
          > .pcoded-hasmenu.pcoded-trigger
          > .pcoded-submenu,
        &[vertical-nav-type='sub-expanded']
          .pcoded-navbar[navbar-theme='theme#{$i}']
          .pcoded-item
          > .pcoded-hasmenu.pcoded-trigger
          > .pcoded-submenu {
          border-left-color: transparent;
        }

        &[vertical-nav-type='ex-popover'] .pcoded-navbar[navbar-theme='theme#{$i}'] .pcoded-item {
          li.pcoded-trigger > a > .pcoded-mcaret {
            border-right-color: $value !important;
          }

          > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
            border-left-color: transparent;
          }
        }

        &[vertical-nav-type='fullpage'] .pcoded-navbar[navbar-theme='theme#{$i}'] {
          background-color: $value;

          .pcoded-item > li:hover > a {
            color: #fff;
          }

          .sidebar_toggle a {
            color: #fff;
          }
        }
      }
    }
    @each $value in $left-navbar-light-theme {
      $i: index($left-navbar-light-theme, $value);

      &[navbar-theme='themelight#{$i}'] .pcoded-item {
        li {
          .pcoded-submenu li.active > a {
            font-weight: 600;
          }
          // &.pcoded-hasmenu:hover > a {
          //     color: #666 !important;
          // }
          &:hover {
            > a {
              // color: #666 !important;
              // background: $value;
            }
            // > a:before {
            //     border-left-color: transparent !important;
            // }
          }
        }
      }

      &[navbar-theme='themelight#{$i}'] {
        .main-menu {
          background-color: $value;

          .main-menu-header {
            background-color: $value;

            .user-details span {
              color: #666;
            }
          }

          .main-menu-content {
            .more-details a {
              color: #666;
            }
          }
        }

        .pcoded-item {
          li.pcoded-hasmenu .pcoded-submenu {
            background: $value;

            li > a {
              border-bottom-color: darken($value, 10%);
              color: #666;
            }
          }

          > li {
            &.pcoded-hasmenu {
              .pcoded-submenu li.pcoded-hasmenu > a:after,
              > a:after {
                color: #666;
              }

              &.pcoded-trigger {
                .pcoded-submenu li.pcoded-hasmenu.pcoded-trigger > a:after,
                > a:after {
                  color: #666;
                }
              }
            }

            &.active:hover > a,
            &:hover > a {
              // background: $value;
              color: #666;
            }
          }

          .pcoded-hasmenu .pcoded-submenu li {
            &:hover {
              > a {
                // background-color: $value;
                color: #666;
              }

              > a:before {
                border-left-color: $value;
              }
            }

            &.active {
              > a {
                color: #666;
                // background-color: #fff;
              }

              > a .pcoded-mtext:before {
                border-bottom-color: $value;
              }

              > a .pcoded-mtext:after {
                border-left-color: $value;
              }
            }

            > a .pcoded-mtext:before {
              border-bottom-color: #fff;
            }

            > a .pcoded-mtext:after {
              border-left-color: #fff;
            }
          }

          > li {
            // &:hover > a:before {
            //     border-left-color: $value;
            // }
            &.pcoded-hasmenu:hover > a:after {
              // color: #666;
            }

            &.pcoded-trigger > a {
              // background: $value;
              border-bottom-color: $value;
            }
          }

          > li {
            // &.pcoded-trigger.active > a {
            //     background: $value;
            // }
            // &.pcoded-hasmenu .pcoded-submenu li.pcoded-hasmenu:hover > a:after {
            //     color: #fff;
            // }
          }
          // > li.pcoded-hasmenu.pcoded-trigger.active > a:after {
          //     color: $value;
          // }
          > li {
            > a {
              color: #666 !important;
            }

            &.active > a {
              // background: $value !important;
              color: #fff;
              // &:before {
              //     border-left-color: $value;
              // }
            }

            > a {
              border-bottom-color: $value;
            }
          }
        }
        // .pcoded-navigation-label {
        //     color: $value;
        //     border-bottom-color: transparent;
        // }
      }
    }
    @each $value in $active-item {
      $i: index($active-item, $value);

      &[active-item-theme='theme#{$i}'] {
        .pcoded-item {
          li {
            .pcoded-submenu {
              li.active,
              li:hover {
                > a {
                  color: $value !important;
                }
              }

              li.active > a {
                font-weight: 600;
              }
            }
            // &.pcoded-hasmenu:hover > a {
            //     color: #FFF !important;
            // }
            &:hover {
              > a {
                color: $value !important;

                .pcoded-micon {
                  color: $value !important;
                }
                // background: $value;
                &:before {
                  border-left-color: transparent;
                }
              }
            }
          }

          > li {
            &.active {
              > a {
                background: rgba(202, 202, 202, 0.3);
                // color: #444 !important;
                //
                // .pcoded-micon {
                //     color: #444 !important;
                // }

                &:before {
                  border-left-color: $value;
                }
              }
            }
          }
          // > li.active > a:after {
          //     color: #FFF !important;
          // }
          .pcoded-hasmenu {
            &[subitem-icon='style7'] {
              .pcoded-submenu {
                border-left: 1px solid #d6d6d6;

                > li {
                  &.active,
                  &:hover {
                    > a > .pcoded-mtext {
                      &:after {
                        background-color: $value;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .searchbar-toggle {
          color: $value;
        }
      }
    }
  }

  &[theme-layout='vertical'][vertical-nav-type='compact'] .pcoded-navbar {
    @each $value in $left-navbar-light-theme {
      $i: index($left-navbar-light-theme, $value);

      &[navbar-theme='themelight#{$i}'] {
        .pcoded-item {
          > li {
            > a {
              border-bottom-color: darken($value, 10%);
            }
          }
        }
      }
    }
    @each $value in $left-navbar-theme {
      $i: index($left-navbar-theme, $value);

      &[navbar-theme='theme#{$i}'] {
        .pcoded-item {
          > li {
            > a {
              border-bottom-color: lighten($value, 10%);
            }
          }
        }
      }
    }
  }

  &[theme-layout='vertical'][vertical-nav-type='collapsed'] {
    .pcoded-navbar {
      @each $value in $left-navbar-light-theme {
        $i: index($left-navbar-light-theme, $value);

        &[navbar-theme='themelight#{$i}'] .pcoded-item > {
          .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
            border-left-color: darken($value, 7%);
            box-shadow: 3px 2px 11px -2px rgba(0, 0, 0, 0.13);
          }

          li.pcoded-trigger > a {
            // border-bottom: 1px solid darken($value, 10%);
            // border-top: 1px solid darken($value, 10%);
          }
        }
      }
      @each $value in $left-navbar-theme {
        $i: index($left-navbar-theme, $value);

        &[navbar-theme='theme#{$i}'] .pcoded-item > {
          .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
            border-left-color: lighten($value, 7%);
            box-shadow: 3px 2px 11px -2px rgba(0, 0, 0, 0.13);
          }

          li.pcoded-trigger > a {
            border-bottom: 1px solid lighten($value, 10%);
            border-top: 1px solid lighten($value, 10%);
          }
        }
      }
    }
  }

  .pcoded-header {
    .navbar-logo {
      // @each $value in $logo-theme {
      //     $i: index($logo-theme, $value);
      //
      //     &[logo-theme="theme#{$i}"] {
      //         background-color: $value;
      //     }
      // }
      // @each $value in $logo-themelight {
      //     $i: index($logo-themelight, $value);
      //
      //     &[logo-theme="themelight#{$i}"] {
      //         background-color: $value;
      //     }
      // }
    }
  }

  &[sidebar-img='true'] {
    .pcoded-navbar {
      background-size: cover;

      .main-menu {
        background-color: transparentize($inverse-color, 0.2);

        .main-menu-header,
        .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active > a,
        .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover > a,
        .pcoded-item li.pcoded-hasmenu .pcoded-submenu {
          background: transparent !important;
          background-color: transparent !important;
        }
        .pcoded-item > li.active > a,
        .pcoded-item > li.active:hover > a,
        .pcoded-item > li.pcoded-trigger > a,
        .pcoded-item > li:hover > a {
          background: rgba(255, 255, 255, 0.1);
        }
        // .pcoded-item > li.active > a:before {
        //     border-left-color: transparent !important;
        // }
      }
    }
  }

  &[theme-layout='vertical'] {
    &[vnavigation-view='view2'] {
      &[pcoded-device-type='desktop'] .pcoded-navbar,
      &[pcoded-device-type='tablet'] .pcoded-navbar {
        .main-menu {
          margin-top: 15px;
          margin-bottom: 15px;
          border-radius: 0;
        }
      }

      &[pcoded-device-type='desktop'] .pcoded-navbar .profile-box,
      &[pcoded-device-type='tablet'] .pcoded-navbar .profile-box {
        border-radius: 4px 4px 0 0;
      }

      &[pcoded-device-type='desktop'] .pcoded-header,
      &[pcoded-device-type='desktop'] .pcoded-main-container,
      &[pcoded-device-type='tablet'] .pcoded-header,
      &[pcoded-device-type='tablet'] .pcoded-main-container {
        padding-left: 15px;
      }

      &[vertical-layout='widebox'] {
        &[pcoded-device-type='desktop'] .pcoded-header .pcoded-wrapper,
        &[pcoded-device-type='tablet'] .pcoded-header .pcoded-wrapper {
          padding-left: 5px;
        }
      }
    }

    &[vnavigation-view='view3'] {
      .pcoded-navbar {
        top: 0;
      }

      .pcoded-main-container {
        position: unset;
      }

      .pcoded-header .pcoded-left-header {
        display: none;
      }
    }
  }

  #styleSelector {
    min-height: 300px;
    height: calc(100vh - 56px);
    border: 1px solid #e9e9e9;
    background: #fff;
    position: fixed;
    margin: 0;
    padding: 20px;
    width: 400px;
    // top: calc(10vh + 56px);
    top: 56px;
    z-index: 100;
    transition: 0.5s;
    -o-transition: 0.5s;

    &.open {
      background: #fff none repeat scroll 0 0;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    }

    a > i {
      padding: 0;
      margin: 0 5px 0 0;
    }

    .soc-icon {
      width: 55px;
      height: 55px;
      padding: 15px;
      border-radius: 50%;

      i {
        font-size: 25px;
        margin-right: 0;
      }
    }
    @media only screen and (max-width: 992px) {
      display: none;
    }
  }

  &[theme-layout='horizontal'],
  &[vertical-placement='left'] {
    #styleSelector {
      right: -400px;
      border-left: 2px solid rgba(48, 53, 73, 0.28);

      &.open {
        right: 0;
      }
    }
  }

  &[vertical-placement='right'] {
    #styleSelector {
      left: -400px;
      border-right: 2px solid rgba(48, 53, 73, 0.28);

      &.open {
        left: 0;
      }
    }
  }
}

.selector-toggle {
  position: relative;
}

.pcoded {
  .selector-toggle > a {
    position: absolute;
    top: 30px;
    width: 45px;
    height: 45px;
    display: block;
    cursor: pointer;
    text-align: center;
    background: linear-gradient(to right, darken($success-color, 15%), lighten($success-color, 10%));
    color: #fff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    padding: 5px 3px;
  }

  &[theme-layout='horizontal'],
  &[vertical-placement='left'] {
    .selector-toggle > a {
      left: -65px;
      border-right: 0;
      border-radius: 50% 0 0 50%;
    }
  }

  &[vertical-placement='right'] {
    .selector-toggle > a {
      right: -65px;
      border-left: 0;
      border-radius: 0 50% 40% 0;
    }
  }
}

.selector-toggle > a:before {
  font: {
    family: 'themify';
    size: 17px;
  }
  content: '\e60f';
  position: relative;
  float: left;
  left: 13px;
  line-height: 35px;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  animation: mymove 1.3s infinite linear;
}

.open .selector-toggle > a:before {
  font: {
    family: 'themify';
    size: 17px;
  }
  content: '\e646';
  position: relative;
  float: left;
  left: 13px;
  line-height: 35px;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  animation: mymove 0s infinite linear;
}

#styleSelector {
  .nav-tabs {
    margin-bottom: 20px;
    border-bottom: 2px solid $primary-color;
    padding-bottom: 5px;

    .nav-item {
      width: calc(100% / 2);
      margin: 0;
      padding: 0;
      float: left;

      a {
        color: $inverse-color;
        text-align: center;
        font-weight: 600;
        font-size: 13px;
        padding: 0.66rem 1rem;
        border-radius: 2px;
        border: none;
        transition: all 0.3s ease-in-out;

        &.active {
          color: #fff;
          background: linear-gradient(to right, darken($primary-color, 15%), lighten($primary-color, 10%));
        }
      }
    }
  }

  .style-cont {
    background-color: #f6f7fb;
    padding: 10px;
  }

  .st-main-title {
    font-size: 18px;
  }

  .btn-success {
    background-color: #11c15b;
  }

  .checkbox-fade label {
    margin-bottom: 0.8rem;
  }

  .sub-title {
    text-transform: capitalize;
  }

  #menu-effect {
    padding-bottom: 15px;

    .radio {
      > label {
        padding-left: 0;
      }

      .helper {
        bottom: -30px;
        left: 3px;

        &:after,
        &:before {
          top: auto;
          bottom: 0;
        }
      }
    }

    .micon {
      font-size: 15px;
      padding: 4px;
      border-radius: 4px;
      width: 30px;
      display: block;
      height: 30px;
      text-align: center;

      &.st1 {
        i {
          color: $primary-color;
        }
        background-color: transparent;
      }

      &.st2 {
        i {
          color: $inverse-color;
        }
        background-color: transparent;
      }
    }
  }

  #bg-pattern-visiblity {
    padding-left: 30px;
  }

  .form-control {
    border: none;
    box-shadow: none;
    background-color: #f1f1f1;
    margin-bottom: 10px;

    &:focus {
      border: none;
      background-color: #f1f1f1;
    }
  }

  ul {
    border: 0 none;
    margin: 0;
    padding: 0;
    width: 100%;

    li {
      border-bottom: 0 solid #e9e9e9;
      display: block;
      margin: 0;
      padding: 0;
      text-align: left;
      width: 100%;

      p.selector-title,
      span.selector-title {
        color: #444;
        font-size: 14px;
        margin: 0 0 15px;
        padding: 10px 0;
        text-align: left;
      }

      p.selector-title {
        font: {
          weight: 600;
        }
        border-bottom: 1px solid #e9e9e9;

        &.main-title {
          font: {
            weight: 600;
          }
        }
      }

      p.sub-title.drp-title {
        border-bottom: 0 solid transparent;
        margin-bottom: 0;
        margin-top: 10px;
      }
    }
  }

  > ul > li > .sub-title {
    color: #9c9c9c;
    display: block;
    font-size: 13px;
    margin: 0;
    padding: 5px 0;
    position: relative;
    text-align: left;
  }

  li {
    padding: 5px;
  }
}

.theme-option select {
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 3px 10px;
}
@-moz-document url-prefix() {
  .theme-option select {
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    border-radius: 2px;
    display: inline-block;
    font: inherit;
    line-height: 1.5em;
    padding: 3px 10px;
    margin: 0;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    &.minimal {
      background-image:
        -webkit-linear-gradient(45deg, transparent 50%, gray 50%),
        -webkit-linear-gradient(315deg, gray 50%, transparent 50%),
        -webkit-linear-gradient(left, #ccc, #ccc);
      background-image: linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
      background-position:
        calc(100% - 18px) calc(1em + -1px),
        calc(100% - 13px) calc(1em + -1px),
        calc(100% - 2.5em) 4px;
      background-size:
        5px 5px,
        5px 5px,
        1px 1.5em;
      background-repeat: no-repeat;

      &:active,
      &:focus {
        background-image:
          -webkit-linear-gradient(45deg, gray 50%, transparent 50%),
          -webkit-linear-gradient(315deg, transparent 50%, gray 50%),
          -webkit-linear-gradient(left, gray, gray);
        background-image: linear-gradient(45deg, gray 50%, transparent 50%),
          linear-gradient(135deg, transparent 50%, gray 50%), linear-gradient(to right, gray, gray);
        background-position:
          calc(100% - 15px) 13px,
          calc(100% - 20px) 13px,
          calc(100% - 2.5em) 0.3em;
        background-size:
          5px 5px,
          5px 5px,
          1px 1.5em;
        background-repeat: no-repeat;
        border-color: #66afe9;
        outline: 0;
      }
    }
  }
}

.theme-color {
  padding: 0;
  width: 100%;

  a {
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    margin: 5px 8px 5px 0;
    box-shadow: 0.4px 0.9px 3px 0 rgba(0, 0, 0, 0.33);
    overflow: hidden;

    &:focus,
    &:hover {
      text-decoration: none;
    }
    width: 45px;
    height: 40px;

    &.small {
      width: 20px;
      height: 20px;
    }

    &.image {
      width: 45px;
      height: 100px;
      background-size: cover;

      .cont,
      .head {
        &:before {
          background-color: #fff;
        }
      }
    }

    .cont,
    .head {
      display: block;
      position: relative;

      &:after,
      &:before {
        content: '';
        height: 100%;
        display: inline-block;
        position: absolute;
      }

      &:after {
        width: 40%;
        left: 0;
      }

      &:before {
        width: 60%;
        right: 0;
      }
    }

    .head {
      height: 10px;

      &:before {
        background: #fff;
      }
    }

    .cont {
      height: 30px;

      &:before {
        background: #f6f7fb;
      }
    }
    @each $value in $menu-title {
      $i: index($menu-title, $value);

      &[lheader-theme='theme#{$i}'] {
        background: $value;
      }
    }
    $temp: 1;
    @each $value in $header-theme {
      $i: index($header-theme, $value);

      &[header-theme='theme#{$i}'] {
        .head {
          // background: linear-gradient(to right, darken($value,15%), lighten($value,10%));
          background: linear-gradient(to right, $value, nth($header-right-theme, $i));

          &:after,
          &:before {
            background: transparent;
          }
        }
      }
    }
    @each $value in $header-theme-light {
      $i: index($header-theme-light, $value);
      &[header-theme='themelight#{$i}'] {
        .head {
          background: linear-gradient(to right, $value, nth($header-right-theme-light, $i));

          &:after,
          &:before {
            background: transparent;
          }
        }
      }
    }
    @each $value in $left-navbar-theme {
      $i: index($left-navbar-theme, $value);

      &[navbar-theme='theme#{$i}'] {
        background: $value;
      }
    }
    // @each $value in $logo-theme {
    //     $i: index($logo-theme, $value);
    //
    //     &[logo-theme="theme#{$i}"] {
    //         .head {
    //             &:after {
    //                 background: $value;
    //             }
    //         }
    //     }
    // }
    // @each $value in $logo-themelight {
    //     $i: index($logo-themelight, $value);
    //
    //     &[logo-theme="themelight#{$i}"] {
    //         .head{
    //             &:after{
    //                 background: $value;
    //             }
    //         }
    //     }
    // }
    @each $value in $left-navbar-light-theme {
      $i: index($left-navbar-light-theme, $value);

      &[navbar-theme='themelight#{$i}'] {
        background: $value;
      }
    }
    @each $value in $active-item {
      $i: index($active-item, $value);

      &[active-item-theme='theme#{$i}'] {
        background: $value;
      }
    }

    &[layout-type='dark'] {
      .cont {
        &:after {
          background: $dark-layout-color;
        }

        &:before {
          background: lighten($dark-layout-color, 10%);
        }
      }

      .head {
        &:after {
          background: lighten($dark-layout-color, 10%);
        }

        &:before {
          background: $dark-layout-color;
        }
      }
    }

    @each $value in $box-bg-color {
      $i: index($box-bg-color, $value);

      &[themebg-pattern='pattern#{$i}'] {
        background: $value;
      }
    }
  }
}

.sparkline-chart {
  text-align: center;
}

.pcoded[theme-layout='vertical'] {
  &[pcoded-device-type='phone'] .spark-chart,
  &[pcoded-device-type='tablet'] .spark-chart {
    display: none;
  }
}

.pcoded {
  &[theme-layout='vertical'] {
    &[vertical-placement='right'] .pcoded-navbar .pcoded-item > li > a .pcoded-micon {
      float: none;
      // padding-right: 17px;
      position: relative;
      z-index: 1051;
      left: 90%;
      top: 1px;
    }

    &[vertical-nav-type='collapsed'][vertical-placement='right'] .pcoded-navbar .pcoded-item > li {
      > a .pcoded-micon i {
        left: 0;
      }

      &.pcoded-hasmenu.pcoded-trigger > a .pcoded-micon i,
      &.pcoded-trigger.active > a .pcoded-micon i {
        left: 94%;
      }
    }

    &[vertical-nav-type='sub-expanded'] {
      &[pcoded-device-type='desktop'] .pcoded-navbar .pcoded-item,
      &[pcoded-device-type='desktop'] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li,
      &[pcoded-device-type='desktop'] .pcoded-navbar .pcoded-item > li,
      &[pcoded-device-type='tablet'] .pcoded-navbar .pcoded-item,
      &[pcoded-device-type='tablet'] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li,
      &[pcoded-device-type='tablet'] .pcoded-navbar .pcoded-item > li {
        position: static;
      }
    }
  }

  &.nocollapsed[theme-layout='vertical'][pcoded-device-type='phone'] .pcoded-main-container {
    position: static;
  }

  &[theme-layout='vertical'] {
    &[vertical-nav-type='fullpage'] .pcoded-main-container,
    &[vnavigation-view='view3'] .pcoded-main-container {
      position: static;
    }
  }

  .pcoded-header {
    $temp: 1;
    @each $value in $header-theme {
      $i: index($header-theme, $value);

      &[header-theme='theme#{$i}'] {
        // background: linear-gradient(to right, $value, nth($header-right-theme,$i));
        background: $value;
        a,
        .input-group-addon {
          color: #fff;
        }
      }
    }
    @each $value in $header-theme-light {
      $i: index($header-theme-light, $value);

      &[header-theme='themelight#{$i}'] {
        // background: linear-gradient(to right, $value, nth($header-right-theme-light,$i));
        background: $value;

        a,
        .input-group-addon {
          color: #fff;
        }
      }
    }
  }
}

body {
  .pcoded {
    &[pcoded-device-type='phone'][vertical-nav-type='expanded'],
    &[pcoded-device-type='tablet'][vertical-nav-type='expanded'] {
      .pcoded-navbar {
        position: absolute !important;

        .pcoded-item .pcoded-hasmenu > .pcoded-submenu {
          transition: all 0s ease-in !important;
        }
      }
    }

    &[theme-layout='vertical'] {
      &[vertical-placement='right'][vertical-nav-type='collapsed'] {
        .pcoded-navbar .pcoded-item > li > a .pcoded-micon {
          left: 0;
        }

        .pcoded-navbar .pcoded-item > li.pcoded-trigger > a .pcoded-micon {
          left: calc(100% - 30px);
        }

        .pcoded-item > li.pcoded-trigger > a > .pcoded-mtext {
          padding-right: 90px;
        }
      }
    }

    &[vertical-placement='right'] {
      .showChat_inner,
      .users {
        right: auto;
        left: 0;
      }

      .main-body {
        direction: rtl;

        .page-wrapper .page-header-breadcrumb {
          float: left;
        }

        .language-markup {
          direction: ltr;
        }
      }

      .header-navbar {
        .navbar-wrapper {
          .navbar-logo {
            float: right !important;
          }

          .navbar-container {
            margin-left: 0;
            margin-right: 240px;

            .nav-left,
            .nav-right {
              li {
                float: right !important;
              }
            }

            .nav-left {
              float: right;
            }

            .nav-right {
              float: left;
            }

            .header-notification,
            .mega-menu-top {
              .profile-notification,
              .show-notification {
                right: auto;
                left: 0;
              }
            }
          }
        }
      }

      &[vertical-nav-type='collapsed'] {
        .header-navbar .navbar-wrapper .navbar-container {
          margin-right: 0;
        }
      }
    }

    &[vertical-nav-type='collapsed'] {
      .header-navbar {
        .navbar-wrapper .navbar-container {
          margin-left: 0;
        }

        .navbar-logo {
          // width: 56px;

          a img {
            // display: none;
          }
        }
      }
      .pcoded-navbar {
        .mCSB_container,
        .mCSB_container_wrapper {
          overflow: hidden !important;
        }
      }
    }

    &[vertical-nav-type='compact'] {
      .main-menu {
        width: 200px;

        .pcoded-item > li > .pcoded-submenu {
          box-shadow: 0 0 16px -6px rgba(0, 0, 0, 0.57);
        }

        .pcoded-search {
          padding: 40px 20px 20px;
        }

        .pcoded-badge {
          display: none;
        }

        .mCSB_container {
          width: 100% !important;
        }
      }
    }
  }

  &.menu-bottom {
    .navbar-wrapper {
      z-index: 1050;
    }

    .pcoded .pcoded-main-container {
      margin-top: 0 !important;
    }

    .p-chat-user,
    .showChat_inner {
      top: 0;
    }

    .navbar-wrapper {
      bottom: 0;
      top: auto;
      z-index: 1050;
      @media only screen and (max-width: 992px) {
        .pcoded-navbar {
          top: -56px;
        }
      }
    }

    .header-navbar {
      position: fixed !important;
      bottom: 0;
      z-index: 9999 !important;
      -webkit-box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.08) !important;
      box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.08) !important;
    }

    .mega-menu-top {
      .show-notification {
        bottom: 0 !important;
      }
    }

    .header-notification {
      .show-notification {
        bottom: 65px;
      }
    }

    #collapse-menu {
      display: none;
    }
  }
}

.mCSB_container,
.mCustomScrollBox {
  overflow: visible !important;
}

.pcoded[theme-layout='vertical'][vertical-nav-type='collapsed'] {
  .mCSB_container_wrapper {
    overflow: visible !important;
  }
}

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: transparentize(nth($left-navbar-theme, 1), 0.5) !important;
}
