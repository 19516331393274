/**  =====================
      Chart css start
==========================  **/

.nvd-chart {
  height: 400px;
}

.peity-chart .peity {
  width: 100%;
  height: 250px;
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

.morris-hover {
  position: absolute;
  min-width: 100px;
  width: 100px;
  right: 0;
  background: #fff;
  padding: 20px;
  border: 1px solid #ccc;
}

/**====== Chart css end ======**/
.rickshaw_graph {
  svg {
    width: 100% !important;
  }
}
