/* Poppins Thin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  src:
    url('/assets/fonts/Poppins/poppins-thin-webfont.woff2') format('woff2'),
    url('/assets/fonts/Poppins/poppins-thin-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  src:
    url('/assets/fonts/Poppins/poppins-thinitalic-webfont.woff2') format('woff2'),
    url('/assets/fonts/Poppins/poppins-thinitalic-webfont.woff') format('woff');
}

/* Poppins Light */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src:
    url('/assets/fonts/Poppins/poppins-light-webfont.woff2') format('woff2'),
    url('/assets/fonts/Poppins/poppins-light-webfont.woff') format('woff');
}

/* Poppins Regular */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src:
    url('/assets/fonts/Poppins/poppins-regular-webfont.woff2') format('woff2'),
    url('/assets/fonts/Poppins/poppins-regular-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  src:
    url('/assets/fonts/Poppins/poppins-italic-webfont.woff2') format('woff2'),
    url('/assets/fonts/Poppins/poppins-italic-webfont.woff') format('woff');
}

/* Poppins Medium */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src:
    url('/assets/fonts/Poppins/poppins-medium-webfont.woff2') format('woff2'),
    url('/assets/fonts/Poppins/poppins-medium-webfont.woff') format('woff');
}

/* Poppins SemiBold */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src:
    url('/assets/fonts/Poppins/poppins-semibold-webfont.woff2') format('woff2'),
    url('/assets/fonts/Poppins/poppins-semibold-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  src:
    url('/assets/fonts/Poppins/poppins-semibolditalic-webfont.woff2') format('woff2'),
    url('/assets/fonts/Poppins/poppins-semibolditalic-webfont.woff') format('woff');
}

// It's too much bold
// /* Poppins Bold */
// @font-face {
//   font-family: 'Poppins';
//   font-style: normal;
//   font-weight: 700;
//   src: url('/assets/fonts/Poppins/poppins-bold-webfont.woff2') format('woff2'),
//        url('/assets/fonts/Poppins/poppins-bold-webfont.woff') format('woff');
// }

@media print {
  @page {
    margin: 0;
  }

  .overlay-hard {
    background: transparent !important;
  }

  .highcharts-button {
    display: none;
  }

  @-moz-document url-prefix() {
    .page,
    body {
      padding-left: 0;
      margin-left: 0;
    }

    @page {
      padding-left: 0;
      margin-left: 0;
      margin-right: 1.6cm;
    }
  }
}

.page {
  color: #333 !important;
}

.btn {
  text-transform: none !important;
}

span {
  text-transform: none;
}

a:hover {
  cursor: pointer;
}

.ogo-link {
  color: #4099ff !important;
  cursor: pointer;
  user-select: none;
}

.to-print {
  display: none;
}

.label {
  text-transform: inherit;
}

.ta-item {
  padding: 0 !important;
  width: 300px !important;
  height: 45px !important;
}

.list-group-item.active {
  background-color: #7c838c !important;
  border-color: #7c838c !important;
}

.tooltip-inner {
  max-width: 300px !important;
  width: 300px !important;
}

.dropdown-item {
  color: #666;
  padding: 6px 20px;
  height: 45px !important;
  line-height: 45px !important;
}

.search-close.close-button {
  & > .icon-close {
    font-weight: bold !important;
    cursor: pointer;
  }
}

datatable-header-cell.datatable-header-cell.sortable.resizeable.ng-star-inserted:before,
datatable-header-cell.datatable-header-cell.sortable.resizeable.ng-star-inserted:after {
  content: '' !important;
}

.pointer {
  cursor: pointer;
}

.weekend:not(.in-range) {
  background-color: #fff !important;
  color: #000 !important;

  &.active {
    background-color: #357ebd !important;
    border-color: transparent !important;
    color: #fff !important;
  }
}

.dataheader {
  margin-top: 12px;
  margin-left: 12px;
}

* {
  outline: inherit;
}

.error-msgs {
  display: block;
  width: 100%;
  color: red;
  text-align: left;
}

.cc-wrapper {
  border-radius: 0px !important;
}

.cc-box__back,
.cc-box__front {
  top: 70px !important;
  height: 270px !important;
  background: linear-gradient(135deg, #4099ff, #2ed8b6) !important;
}

.cc-form__label--first {
  margin-top: 25% !important;
}

.cc-box {
  width: 480px !important;
}

.jp-card-container {
  transform: scale(1) !important;
}

*[disabled] {
  cursor: not-allowed;
}
i.icon {
  cursor: pointer;
}

.highcharts-container {
  // min-height: 250px !important;
}

.flex {
  display: flex;

  &.space-between {
    justify-content: space-between;
  }
}

.flex-align-items {
  display: flex;
  align-items: center;

  &.space-between {
    justify-content: space-between;
  }

  &.wrap {
    flex-wrap: wrap;
  }
}

.align-center {
  text-align: center;
}

.app-modal-header {
  display: flex;
  align-items: center;

  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  h4 {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .close.basic-close {
    font-size: 21px !important;
    outline: none;
    top: unset;
  }
}

.table th {
  padding: 0.65rem;
}

.ellipsis::after {
  content: none;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotating::before {
  animation: rotating 2s linear infinite;
}

.grey-disable {
  color: #969696;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }

  to {
    width: 0;
    background: transparent;
  }
}

@-moz-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }

  to {
    width: 0;
    background: transparent;
  }
}

/* Material form starting stylings ------------------------------- */
.group {
  position: relative;
  margin-bottom: 20px;
}

input:focus {
  outline: none;
}

/* LABEL ======================================= */
label.mat-label {
  color: #999;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 2px;
  top: 18px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
input:focus ~ label,
input:valid ~ label {
  top: -20px;
  font-size: 14px;
  color: #5264ae;
}

/* BOTTOM BARS ================================= */
.bar {
  position: relative;
  display: block;
  width: 100%;
}

.bar:before,
.bar:after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #5264ae;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.bar:before {
  left: 50%;
}

.bar:after {
  right: 50%;
}

input.md {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
}

/* active state */
input:focus ~ .bar:before,
input:focus ~ .bar:after {
  width: 50%;
}

/* HIGHLIGHTER ================================== */
.highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

/* active state */
input:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

input.form-control.form-control-sm::placeholder {
  font-weight: normal;
}

@keyframes inputHighlighter {
  from {
    background: #5264ae;
  }

  to {
    width: 0;
    background: transparent;
  }
}

// To avoid red required input in firefox/safari
input:required {
  box-shadow: none;
}

// Switch toggle button
.switch-container {
  & > span {
    color: #7d7d7d;

    &:first-child {
      margin-right: 5px;
    }
    &:last-child {
      margin-left: 5px;
    }
  }
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 52px;
  height: 23px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 15px;
  width: 15px;
  left: 6px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
// TODO: use <app-switch> and remove
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

// end switch toggle button

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 4px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  padding: 1rem;
  padding-right: 2rem;
}

select.form-control {
  padding-right: 1.5rem;
}

.text-placeholder {
  &.cards {
    height: 31px;
  }

  &.resolve {
    height: 15px;
    margin-left: 7px;
    background-color: #f1f1f1;

    & > .activity {
      background-image: linear-gradient(
        to left,
        rgba(220, 220, 220, 0.05),
        rgba(220, 220, 220, 0.1),
        rgba(220, 220, 220, 0.2),
        rgba(220, 220, 220, 0.1),
        rgba(220, 220, 220, 0.05)
      );
    }
  }

  &.to-print {
    height: 21px;
  }

  position: relative;
  height: 31px;
  width: 75px;
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 44;
  overflow: hidden;
  border-radius: 5px;

  & > .activity {
    position: absolute;
    left: -45%;
    height: 100%;
    width: 45%;
    background-image: linear-gradient(
      to left,
      rgba(251, 251, 251, 0.05),
      rgba(251, 251, 251, 0.1),
      rgba(251, 251, 251, 0.2),
      rgba(251, 251, 251, 0.1),
      rgba(251, 251, 251, 0.05)
    );
    animation: loading 1s infinite;
    z-index: 45;
  }
}

@keyframes loading {
  0% {
    left: -45%;
  }
  100% {
    left: 100%;
  }
}

.delegation-table {
  td {
    padding: 10px;
    vertical-align: unset;
  }

  tr > td:first-child {
    width: 10px;
    padding: 13px;
  }

  tr > td:not(:first-child):not(:last-child) {
    padding: 0.4rem 0.6rem !important;
    max-width: 100px;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  tr > td:last-child {
    width: 10px;

    & > i {
      display: flex;
      justify-content: space-around;
    }
  }

  tr > td:nth-child(5) {
    width: 10px;

    .radio-inline {
      margin: unset;
      padding: 0;
    }
  }

  th {
    padding: 0.6rem !important;
  }
}

th.date {
  width: 10rem;
}

.journal-table.table-xs {
  th,
  td {
    padding: 0.4rem 1rem;
  }
  .expand-button {
    width: 10px;
  }
}

.ogo-logo-login {
  margin-bottom: 35px;
}

.bg-red {
  color: red;
  padding: 0.5em 1em !important;
  margin-top: 10px;
  border: 1px solid red;
  border-radius: 3px;
  background-color: #ffe8e8;
}

.bg-grey {
  color: #333;
  padding: 0.5em 1em !important;
  margin-top: 10px;
  border: 1px solid rgb(69, 69, 69);
  border-radius: 3px;
  background-color: #f9f9f9;
  text-align: left;
  width: 100%;
}

.bg-orange {
  color: #000000;
  padding: 0.5em 1em !important;
  margin-top: 10px;
  border: 1px solid #ff9900;
  border-radius: 3px;
  background-color: rgba(255, 141, 0, 0.2);
}

.bg-blue {
  color: #4099ff;
  padding: 0.5em 1em !important;
  margin-top: 10px;
  border: 1px solid #4099ff;
  border-radius: 3px;
  background-color: #e8f2ff;
  text-align: center;
}

[class^='bg-'].text-left {
  text-align: left;
}

/* Customize the label (the container) */
.ogo-checkbox-container {
  display: block;
  height: 25px;
  width: 25px;
  position: relative;
  padding-left: 26px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.ogo-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.ogo-checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border: 1px solid #aba9a9;
  border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.ogo-checkbox-container:hover input ~ .ogo-checkbox-checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.ogo-checkbox-container input:checked ~ .ogo-checkbox-checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.ogo-checkbox-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.ogo-checkbox-container input:checked ~ .ogo-checkbox-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.ogo-checkbox-container .ogo-checkbox-checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.btn-primary.disabled,
.btn-primary:disabled {
  opacity: 0.4;
  &:hover {
    background-color: #007bff;
    opacity: 0.4;
  }
}

.mode-letter {
  font-size: 13px;
  cursor: pointer;
  float: right;
  margin-right: 10px;
}

.main-menu .icon-search {
  position: relative;
  top: 2px;
}

.clickable {
  text-decoration: underline !important;
  cursor: pointer;
}

.clf {
  color: #464646;
  font-weight: bold;
}

.client-ip {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 75%;
}

.ngx-datatable.data-table {
  border-radius: 5px;
}

.datatable-body-cell-label {
  width: 100%;
}

.datatable-body-cell {
  display: flex !important;
  align-items: center;
  padding: 0 10px !important;
}

.datatable-body-cell-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.number .datatable-body-cell-label,
.duration .datatable-body-cell-label {
  text-align: right;
  font-family: 'helvetica';
}

.datatable-header-cell.number .dataheader,
.datatable-header-cell.duration .dataheader {
  margin-right: 0;
  margin-left: 0;
  padding-right: 10px;
  text-align: right;
}

.status-code .datatable-body-cell-label {
  text-align: center;
  font-family: 'helvetica';
}

.date .datatable-body-cell-label,
td.date,
div.date {
  font-family: 'helvetica';
}

.ip-address {
  font-family: 'helvetica';
}

.overlay {
  position: absolute;
  height: 100%;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.3);
}

.overlay-hard {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 100000000;
  background-color: white;
  padding: 20px;

  &.complete {
    top: -56px;
    left: -235px;

    @media (max-width: 1024px) {
      left: 0;
    }
  }
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table-fixed {
  table-layout: fixed;
  width: 100%;
}

#host-label {
  float: left;
  position: relative;
  top: 11px;
  margin: 0 10px;
  font-size: 15px;
}

.multiple-action-table-container {
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 30px;
}

.icon-warning:before {
  color: #ff3f3f;
  left: 5px;
  position: relative;
  top: 2px;
  font-size: 17px;
}

#default-fixed-menu {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  z-index: 1;
  margin-top: -39px;

  @media (max-width: 767px) {
    label:not([class^='badge']) {
      margin: 0 10px !important;
      width: 55px;
    }
  }

  .switch-refresh {
    position: relative;
    top: 5px;
    margin: 0 10px;

    label {
      text-wrap: nowrap;
      color: #4099ff;
      position: relative;
      left: 6px;
      top: -2px;
      padding: 6px 3px 0px 0px;
    }

    .loading-bar {
      border-radius: 2px;
      transition: all 0.2s;
      height: 3px;
      background-color: #4099ff;
    }
  }

  [id^='reportrange'] {
    span {
      position: relative;
      top: 1px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

[id^='reportrange'] {
  display: flex;
  align-items: center;
  height: 34px;
}

.btn-reload {
  font-size: 3px;
  height: 34px;

  .icon-reload {
    font-size: 12px;
    position: relative;
    left: -1px;
  }
}

.tabs-over-graph {
  .btn-md {
    font-size: 13px;
  }
}

.arrow-down {
  position: relative;
  left: 6px;
  top: 1px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid white;
}

.input-prefix {
  display: inline;
  position: absolute;
  border: 1px solid #dbd9da;
  padding: 0px 10px 10px;
  background: rgb(248, 249, 249);
}

.fi {
  border: 1px solid #858585;
}

.transparent-btn {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  padding: 0px !important;
  outline-color: #4099ff !important;
  height: 25px;
  &:active {
    outline: none;
  }
}

.badge-outline {
  border: 1px solid #4099ff;
  border-radius: 3px;
  color: #4099ff !important;
  background: transparent;
}

$custom-scrollbar-width: 6px;
$custom-scrollbar-track-background-color: #f1f1f1;
$custom-scrollbar-track-border-radius: 10px;
$custom-scrollbar-thumb-color: #888;
$custom-scrollbar-thumb-color-hover: #555;
$custom-scrollbar-thumb-border-radius: 10px;

.custom-scrollbar {
  &::-webkit-scrollbar {
    width: $custom-scrollbar-width;
  }

  &::-webkit-scrollbar-track {
    border-radius: $custom-scrollbar-track-border-radius;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $custom-scrollbar-track-background-color;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $custom-scrollbar-thumb-color;
    border-radius: $custom-scrollbar-thumb-border-radius;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $custom-scrollbar-thumb-color-hover;
  }
}

::ng-deep .ng-select.custom .ng-clear-wrapper .ng-clear {
  position: relative !important;
  top: 2px !important;
}

// have to repeat because we don't have access to this .ng-select-container element
.selector ::ng-deep .ng-select-container {
  border-radius: 2px;

  &::-webkit-scrollbar {
    width: $custom-scrollbar-width;
  }

  &::-webkit-scrollbar-track {
    border-radius: $custom-scrollbar-track-border-radius;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $custom-scrollbar-track-background-color;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $custom-scrollbar-thumb-color;
    border-radius: $custom-scrollbar-thumb-border-radius;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $custom-scrollbar-thumb-color-hover;
  }
}

.blue-bullet-point {
  width: 5px;
  height: 5px;
  margin-right: 3px;
  background: #4099ff;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  top: -3px;
}

label.star::after,
label.required::after {
  content: '* ';
  color: red;
  position: relative;
  left: 1px;
  font-weight: bold;
}

.row-highlight {
  font-weight: bold;
}

.total-admin {
  position: relative;
  top: 3px;
  color: #5e5e5e;
}

.ngx-toastr {
  transition: all 0.3s;
}

.toast-success {
  background-color: linear-gradient(45deg, #2ed8b6, #59e0c5);
  color: #fff;
}

.toast-info {
  background-color: #4099ff;
  color: #fff;
}

.toast-error {
  background-color: linear-gradient(45deg, #ff5370, #ff869a);
  color: #fff;
}

.toast-warn {
  background-color: linear-gradient(45deg, #f7a35c, #f7a35c);
  color: #fff;
}

.btn-group {
  button {
    border: 1px solid #e7e7e7;
    border-top: none;
    border-radius: 3px;
  }
}

.btn-group {
  .btn-square:not(.btn-inverse) {
    &:hover {
      background: rgb(151 151 151 / 14%);
    }
  }
}

.btn-inverse:hover {
  background-color: #222;
  color: white;
}

.text-no-wrap {
  text-wrap: nowrap;
}

.unset-width {
  width: unset !important;
}

.share-VIEW {
  background-color: #f39c12;
}

.share-ADMIN {
  background-color: #2ecc71;
}

.chart-interval {
  height: 0;
  position: relative;
  // top: 10px;
  left: 43px;
  font-size: 11px;
  color: #7c7c7c;
  z-index: 2;
}

.datatable-scroll {
  width: 100% !important;
}

.ogo-ngx-datatable-footer {
  text-wrap: nowrap;
  color: #6e6e6e;
}

.card {
  &.with-tabs {
    border-top-left-radius: 0;
  }
  .btn-group {
    button:first-child {
      border-top-left-radius: 5px;
    }
  }
}

// workaround space at the bottom of header cells
.datatable-header-cell-template-wrap .datatable-icon-sort-unset {
  display: inline !important;
}

app-print {
  .ngx-datatable {
    .datatable-footer {
      display: none !important;
    }

    .datatable-header .datatable-header-inner,
    .datatable-body datatable-body-row {
      width: 100% !important;
    }
  }
}

.tooltip-menu {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 0.7rem 1rem rgba(0, 0, 0, 0.35);
  z-index: 1000;
  border: 1px solid #dbd9da;
  border-radius: 5px;
  max-width: 700px;
}

.tooltip-menu.show {
  display: block;
}

.tooltip-item {
  font-size: 13px;
  padding: 5px 16px 8px 8px;
  cursor: pointer;
  text-decoration: none;
  color: #333;

  i {
    margin-right: 7px;
    display: inline-block;
    width: 15px;

    &.icofont-ui-delete {
      color: #e74c3c;
    }

    &.icofont-edit-alt {
      color: #4099ff;
    }

    &.icofont-edit-alt {
      color: #4099ff;
    }

    &.icofont-eye-blocked {
      font-size: 15px;
      position: relative;
      left: -1px;
    }

    &.icon-arrows-horizontal {
      position: relative;
      top: 1px;
      color: #4099ff;
    }

    &.icofont-eye {
      font-size: 15px;
      position: relative;
      left: -1px;
    }
  }
}

.tooltip-item:hover {
  background-color: #f8f9fa;
}

.g-10 {
  gap: 10px;
}

.tabs-over-graph {
  background: white;
  border-top: 1px solid #e7e7e7;
  position: relative;
  left: -1px;
  z-index: 2;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  & > .btn:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 0;
  }
  & > .btn:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 0;
  }
}

.nav-tabs {
  .nav-link.active {
    background-color: black;
    color: white;
  }
  .nav-link {
    background: white;
    border: 1px solid #e7e7e7;
    border-radius: 0;
    z-index: 2;
    position: relative;
    right: 1px;

    .badge {
      top: 0px;
    }
  }
}

th.actions {
  width: 20px;
}

td.actions,
datatable-body-cell.actions,
div.icon-container {
  &,
  .datatable-body-cell-label {
    display: flex;
    gap: 1ex;
  }

  i {
    color: #4099ff;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.5s;
  }
  i.disabled,
  span.disabled i,
  a.disabled i {
    color: #eeeeee;
    cursor: default;
  }

  span.icon-container {
    position: relative;
    .badge1 {
      position: absolute;
    }
  }
}

td.actions,
datatable-body-cell.actions {
  i.icofont-ui-delete {
    color: #e74c3c;
  }
}

span.badge a {
  font-size: inherit;
}

.table-striped tbody tr:nth-of-type(2n + 1) {
  td.actions,
  datatable-body-cell.actions {
    i.disabled,
    span.disabled i,
    a.disabled i {
      color: #e8e8e8;
    }
  }
}

.input-group input ~ .btn {
  border-radius: 0 2px 2px 0;
  padding: 0.375rem 0.75rem;
}
.input-group .input-group-text {
  font-size: 14px;
}

.input-group.date input,
.form-control[type='number'] {
  max-width: 10em;
}

.form-group {
  label {
    display: block;
  }
}

.col-xxl-6 {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  /*
  position: relative;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
   */
}

@media (min-width: 1400px) {
  .col-xxl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
