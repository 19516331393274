/* ======================
    All Icon Common Page css
======================== */

.data-table-main.icon-list-demo [class*='col-'] {
  margin-bottom: 10px;
}

.data-table-main.icon-svg-demo [class*='col-'] {
  margin-bottom: 10px;
}

.icon-list-demo i {
  border: 1px solid #eceeef;
  border-radius: 3px;
  color: rgba(43, 61, 81, 0.7);
  display: inline-block;
  font-size: 24px;
  height: 50px;
  line-height: 50px;
  margin: 0 12px 0 0;
  text-align: center;
  vertical-align: middle;
  width: 50px;
}

.icon-list-demo div {
  cursor: pointer;
  white-space: nowrap;
  margin-bottom: 10px;
}

.icon-list-demo i:hover {
  color: #64b0f2;
}

/**====== Flag-icon css start ======**/

.flags .f-item {
  padding: 12px;
  border: 1px solid #ddd;
  margin-right: 15px;
  display: inline-block;
}

.data-table-main.flags [class*='col-'] {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.content-flag label {
  margin-bottom: 0;
  cursor: pointer;
}

.content-flag {
  .txt-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 120px;
    overflow: hidden;
  }
}

.outer-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  width: 250px;
}

label.txt-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  width: 135px;
}

.flags .f-item .name,
.flags .f-item .capital {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-bottom: 0;
}

.flags .f-item .code {
  display: block;
  font-size: 90%;
  color: #999;
  padding-bottom: 0;
}

/**====== All icon common page css end ======**/
